import axios from 'axios';
import { startLoading, stopLoading } from '../redux/loadingSlice';
import { setError } from '../redux/errorSlice';
import store from '../redux/store';

const api = axios.create({
  baseURL: 'https://back-end-sindicatoclaro2.onrender.com', // Base URL de la API
});

// Interceptor para añadir el token en cada solicitud
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error('Error en el interceptor de Axios:', error);
    return Promise.reject(error);
  }
);

// Interceptor para activar el spinner en cada solicitud
api.interceptors.request.use(
  (config) => {
    store.dispatch(startLoading()); // Activa el spinner
    return config;
  },
  (error) => {
    store.dispatch(stopLoading()); // Desactiva el spinner en caso de error
    return Promise.reject(error);
  }
);

// Interceptor para manejar respuestas y errores
api.interceptors.response.use(
  (response) => {
    store.dispatch(stopLoading()); // Desactiva el spinner tras respuesta exitosa
    return response;
  },
  (error) => {
    store.dispatch(stopLoading()); // Desactiva el spinner tras error

    // Manejo de errores HTTP
    if (error.response) {
      const { status, data } = error.response;
      store.dispatch(
        setError({
          message: data.message || 'Ocurrió un error inesperado.',
          status: status,
          redirectTo: status === 403 ? '/cambio-clave' : null, // Redirección para errores 403
        })
      );
    } else if (error.request) {
      // Error cuando no se recibe respuesta del servidor
      store.dispatch(
        setError({
          message: 'No se recibió respuesta del servidor.',
          status: 500,
        })
      );
    } else {
      // Error al configurar la solicitud
      store.dispatch(
        setError({
          message: 'Error al configurar la solicitud.',
          status: 500,
        })
      );
    }
    return Promise.reject(error); // Propaga el error para manejo adicional
  }
);

export default api;
