import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Card, Button, Spinner, Placeholder } from 'react-bootstrap';

const NotFoundPage = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000); // Simula un tiempo de carga
  }, []);

  return (
    <Container className="d-flex justify-content-center align-items-center vh-100">
      <Card className="text-center shadow-lg p-4" style={{ maxWidth: '400px' }}>
        <Card.Body>
          {loading ? (
            <Spinner animation="border" variant="danger" className="mb-3" />
          ) : (
            <>
              <FontAwesomeIcon icon="fa-solid fa-ban" bounce size="2x" style={{ color: "#e70808" }} />
              <h1 className="mt-3 text-danger fw-bold">404</h1>
              <p className="text-muted">Lo sentimos, la página que buscas no existe.</p>
              <Button variant="danger" as={Link} to="/" className="fw-bold px-4">
                Volver al inicio
              </Button>
            </>
          )}
          {loading && (
            <Placeholder as="p" animation="glow">
              <Placeholder xs={8} />
            </Placeholder>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default NotFoundPage;
