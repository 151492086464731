import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchEncuestas, crearEncuesta, eliminarEncuesta, fetchPreguntasByEncuestaId } from '../../redux/encuestasSlice';
import { Table, Button, Modal, Form, Alert, Spinner, Placeholder } from 'react-bootstrap';
import Swal from 'sweetalert2';
import api from '../../utils/axiosConfig';

const EncuestasPage = () => {
  const dispatch = useDispatch();
  const { encuestas, error, preguntas, loading } = useSelector((state) => state.encuestas);

  useEffect(() => {
    dispatch(fetchEncuestas());
  }, [dispatch]);

  const [modalState, setModalState] = useState({
    showEncuestaModal: false,
    showPreguntaModal: false,
  });
  const [encuestaForm, setEncuestaForm] = useState({ titulo: '', descripcion: '' });
  const [preguntaForm, setPreguntaForm] = useState({
    pregunta: '',
    tipoRespuesta: 'opcion_multiple',
    alternativas: [''],
  });
  const [selectedEncuestaId, setSelectedEncuestaId] = useState(null);
  const [editMode, setEditMode] = useState({ isEditing: false, preguntaId: null });
  const [errors, setErrors] = useState({ encuesta: null, pregunta: null });
  const [isCreatingEncuesta, setIsCreatingEncuesta] = useState(false);
  const [isUpdatingPregunta, setIsUpdatingPregunta] = useState(false);

  const handleShowModal = (modalName, state) => {
    setModalState((prev) => ({ ...prev, [modalName]: state }));
  };

  const handleChangeEncuesta = (e) => {
    const { name, value } = e.target;
    setEncuestaForm((prev) => ({ ...prev, [name]: value }));

    // Validación en tiempo real para el título
    if (name === 'titulo') {
      if (value.length <= 10) {
        setErrors((prev) => ({ ...prev, encuesta: 'El título debe tener más de 10 caracteres.' }));
      } else {
        setErrors((prev) => ({ ...prev, encuesta: null }));
      }
    }

    // Validación en tiempo real para la descripción
    if (name === 'descripcion') {
      if (value.length > 500) {
        setErrors((prev) => ({ ...prev, encuesta: 'La descripción no debe exceder los 500 caracteres.' }));
      } else {
        setErrors((prev) => ({ ...prev, encuesta: null }));
      }
    }
  };

  const handleCrearEncuesta = async (e) => {
    e.preventDefault();

    // Validación para el título
    if (encuestaForm.titulo.length <= 10) {
      setErrors((prev) => ({ ...prev, encuesta: 'El título debe tener más de 10 caracteres.' }));
      return;
    }

    // Validación para la descripción
    if (encuestaForm.descripcion && encuestaForm.descripcion.length > 500) {
      setErrors((prev) => ({ ...prev, encuesta: 'La descripción no debe exceder los 500 caracteres.' }));
      return;
    }

    setIsCreatingEncuesta(true);

    try {
      await dispatch(crearEncuesta({ ...encuestaForm, activo: true })).unwrap();
      handleShowModal('showEncuestaModal', false);
      setEncuestaForm({ titulo: '', descripcion: '' });
      setErrors((prev) => ({ ...prev, encuesta: null }));
      dispatch(fetchEncuestas());
      Swal.fire('Éxito', 'Encuesta creada correctamente.', 'success');
    } catch (err) {
      setErrors((prev) => ({ ...prev, encuesta: err.message || 'Error al crear la encuesta.' }));
      Swal.fire('Error', err.message || 'Error al crear la encuesta.', 'error');
    } finally {
      setIsCreatingEncuesta(false);
    }
  };

  const handleEliminarEncuesta = (id) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'No podrás revertir esta acción.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(eliminarEncuesta(id))
          .then(() => {
            Swal.fire('Éxito', 'Encuesta eliminada correctamente.', 'success');
            dispatch(fetchEncuestas());
          })
          .catch((err) => Swal.fire('Error', err.message || 'Error al eliminar la encuesta.', 'error'));
      }
    });
  };

  const handleAbrirModalPregunta = (id) => {
    setSelectedEncuestaId(id);
    dispatch(fetchPreguntasByEncuestaId(id));
    handleShowModal('showPreguntaModal', true);
  };

  const handleChangePregunta = (e) => {
    const { name, value } = e.target;
    setPreguntaForm((prev) => ({ ...prev, [name]: value }));

    // Validación en tiempo real para la pregunta
    if (name === 'pregunta') {
      if (value.length <= 10) {
        setErrors((prev) => ({ ...prev, pregunta: 'La pregunta debe tener más de 10 caracteres.' }));
      } else {
        setErrors((prev) => ({ ...prev, pregunta: null }));
      }
    }
  };

  const handleCrearPregunta = async () => {
    const { pregunta, tipoRespuesta, alternativas } = preguntaForm;

    // Validar longitud de la pregunta
    if (pregunta.trim().length <= 10) {
      setErrors((prev) => ({ ...prev, pregunta: 'La pregunta debe tener más de 10 caracteres.' }));
      return;
    }

    // Validar alternativas en preguntas de opción múltiple
    if (tipoRespuesta === 'opcion_multiple') {
      const alternativasValidas = alternativas.filter((alt) => alt.trim().length > 1);
      if (alternativasValidas.length < 2) {
        setErrors((prev) => ({ ...prev, pregunta: 'Debe haber al menos dos alternativas válidas, con más de un carácter.' }));
        return;
      }
    }

    setIsUpdatingPregunta(true);

    try {
      const payload = {
        pregunta: pregunta.trim(),
        tipo_respuesta: tipoRespuesta,
        alternativas: tipoRespuesta === 'opcion_multiple' 
          ? alternativas.filter((alt) => alt.trim().length > 1) 
          : null,
      };

      if (editMode.isEditing) {
        await api.put(`/api/encuestas/preguntas/${editMode.preguntaId}`, payload);
        Swal.fire('Éxito', 'Pregunta actualizada correctamente.', 'success');
      } else {
        await api.post(`/api/encuestas/${selectedEncuestaId}/preguntas`, payload);
        Swal.fire('Éxito', 'Pregunta añadida correctamente.', 'success');
      }

      dispatch(fetchPreguntasByEncuestaId(selectedEncuestaId));
      setPreguntaForm({ pregunta: '', tipoRespuesta: 'opcion_multiple', alternativas: [''] });
      setEditMode({ isEditing: false, preguntaId: null });
      setErrors({});
    } catch (error) {
      console.error('Error al gestionar la pregunta:', error);
      setErrors((prev) => ({
        ...prev,
        pregunta: error.response?.data?.message || 'Error al gestionar la pregunta.',
      }));
      Swal.fire('Error', error.response?.data?.message || 'Error al gestionar la pregunta.', 'error');
    } finally {
      setIsUpdatingPregunta(false);
    }
  };

  const handleEliminarPregunta = async (id) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'No podrás revertir esta acción.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await api.delete(`/api/encuestas/preguntas/${id}`);
          dispatch(fetchPreguntasByEncuestaId(selectedEncuestaId));
          Swal.fire('Éxito', 'Pregunta eliminada correctamente.', 'success');
        } catch (error) {
          console.error('Error al eliminar pregunta:', error);
          Swal.fire('Error', error.response?.data?.message || 'Error al eliminar la pregunta.', 'error');
        }
      }
    });
  };

  const handleEditarPregunta = (id, preguntaText, tipo, alternativasArray) => {
    setEditMode({ isEditing: true, preguntaId: id });
    setPreguntaForm({ pregunta: preguntaText, tipoRespuesta: tipo, alternativas: alternativasArray || [''] });
  };

  const handleAddAlternativa = () => setPreguntaForm((prev) => ({ ...prev, alternativas: [...prev.alternativas, ''] }));

  const handleRemoveAlternativa = (index) => {
    setPreguntaForm((prev) => ({
      ...prev,
      alternativas: prev.alternativas.filter((_, i) => i !== index),
    }));
  };

  const handleAlternativaChange = (value, index) => {
    setPreguntaForm((prev) => {
      const nuevasAlternativas = [...prev.alternativas];
      nuevasAlternativas[index] = value;

      if (prev.tipoRespuesta === 'opcion_multiple') {
        const alternativasValidas = nuevasAlternativas.filter((alt) => alt.trim().length > 1);
        if (alternativasValidas.length < 2) {
          setErrors((prevErrors) => ({ ...prevErrors, pregunta: 'Debe haber al menos dos alternativas válidas, con más de un carácter.' }));
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, pregunta: null }));
        }
      }

      return { ...prev, alternativas: nuevasAlternativas };
    });
  };

  return (
    <div className="container mt-4">
      <h1 className="text-center">Gestión de Encuestas</h1>

      {error && <Alert variant="danger">{error.message || 'Ocurrió un error inesperado'}</Alert>}
      {errors.encuesta && <Alert variant="danger">{errors.encuesta}</Alert>}
      {errors.pregunta && <Alert variant="danger">{errors.pregunta}</Alert>}

      <div className="d-flex justify-content-end mb-3">
        <Button variant="primary" onClick={() => handleShowModal('showEncuestaModal', true)}>
          Crear Encuesta
        </Button>
      </div>

      {loading ? (
        <Placeholder as="p" animation="glow">
          <Placeholder xs={12} size="lg" />
          <Placeholder xs={12} size="lg" />
          <Placeholder xs={12} size="lg" />
        </Placeholder>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Título</th>
              <th>Descripción</th>
              <th>Activo</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {encuestas.length > 0 ? (
              encuestas.map((encuesta, index) => (
                <tr key={encuesta.id}>
                  <td>{index + 1}</td>
                  <td>{encuesta.titulo}</td>
                  <td>{encuesta.descripcion}</td>
                  <td>{encuesta.activo ? 'Sí' : 'No'}</td>
                  <td>
                    <Button
                      variant="primary"
                      size="sm"
                      className="me-2"
                      onClick={() => handleAbrirModalPregunta(encuesta.id)}
                    >
                      Gestionar Preguntas
                    </Button>
                    <Button variant="danger" size="sm" onClick={() => handleEliminarEncuesta(encuesta.id)}>
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  No hay encuestas disponibles
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      )}

      <Modal show={modalState.showEncuestaModal} onHide={() => handleShowModal('showEncuestaModal', false)}>
        <Modal.Header closeButton>
          <Modal.Title>Crear Nueva Encuesta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleCrearEncuesta}>
            <Form.Group className="mb-3">
              <Form.Label>Título</Form.Label>
              <Form.Control
                type="text"
                name="titulo"
                placeholder="Ingrese el título de la encuesta (mínimo 10 caracteres)"
                value={encuestaForm.titulo}
                onChange={handleChangeEncuesta}
                isInvalid={errors.encuesta && encuestaForm.titulo.length <= 10}
              />
              <Form.Control.Feedback type="invalid">
                El título debe tener más de 10 caracteres.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="descripcion"
                placeholder="Ingrese una descripción opcional (máximo 500 caracteres)"
                value={encuestaForm.descripcion}
                onChange={handleChangeEncuesta}
                isInvalid={errors.encuesta && encuestaForm.descripcion.length > 500}
              />
              <Form.Control.Feedback type="invalid">
                La descripción no debe exceder los 500 caracteres.
              </Form.Control.Feedback>
            </Form.Group>

            <Button variant="primary" type="submit" disabled={isCreatingEncuesta}>
              {isCreatingEncuesta ? (
                <>
                  <Spinner as="span" size="sm" animation="border" role="status" aria-hidden="true" />
                  <span className="ms-2">Creando...</span>
                </>
              ) : (
                'Crear Encuesta'
              )}
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleShowModal('showEncuestaModal', false)}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalState.showPreguntaModal} onHide={() => handleShowModal('showPreguntaModal', false)}>
        <Modal.Header closeButton>
          <Modal.Title>Gestionar Preguntas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Pregunta</Form.Label>
              <Form.Control
                type="text"
                name="pregunta"
                placeholder="Ingrese la pregunta (mínimo 10 caracteres)"
                value={preguntaForm.pregunta}
                onChange={handleChangePregunta}
                isInvalid={errors.pregunta && preguntaForm.pregunta.length <= 10}
              />
              <Form.Control.Feedback type="invalid">
                La pregunta debe tener más de 10 caracteres.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Tipo de Respuesta</Form.Label>
              <Form.Select
                name="tipoRespuesta"
                value={preguntaForm.tipoRespuesta}
                onChange={handleChangePregunta}
              >
                <option value="opcion_multiple">Opción Múltiple</option>
                <option value="abierta">Abierta</option>
              </Form.Select>
            </Form.Group>

            {preguntaForm.tipoRespuesta === 'opcion_multiple' && (
              <Form.Group className="mb-3">
                <Form.Label>Alternativas</Form.Label>
                {preguntaForm.alternativas.map((alt, index) => (
                  <div key={index} className="d-flex align-items-center mb-2">
                    <Form.Control
                      type="text"
                      placeholder={`Alternativa ${index + 1}`}
                      value={alt}
                      onChange={(e) => handleAlternativaChange(e.target.value, index)}
                      isInvalid={alt.trim().length > 0 && alt.trim().length <= 1}
                    />
                    <Form.Control.Feedback type="invalid">
                      Cada alternativa debe tener más de un carácter.
                    </Form.Control.Feedback>
                    <Button variant="danger" size="sm" className="ms-2" onClick={() => handleRemoveAlternativa(index)}>
                      Eliminar
                    </Button>
                  </div>
                ))}
                <Button variant="success" size="sm" onClick={handleAddAlternativa}>
                  Añadir Alternativa
                </Button>
              </Form.Group>
            )}
          </Form>

          <Button variant="primary" className="me-2" onClick={handleCrearPregunta} disabled={isUpdatingPregunta}>
            {isUpdatingPregunta ? (
              <>
                <Spinner as="span" size="sm" animation="border" role="status" aria-hidden="true" />
                <span className="ms-2">Actualizando...</span>
              </>
            ) : editMode.isEditing ? (
              'Actualizar'
            ) : (
              'Añadir'
            )}
          </Button>
          {editMode.isEditing && (
            <Button variant="secondary" onClick={() => setEditMode({ isEditing: false, preguntaId: null })}>
              Cancelar
            </Button>
          )}

          <hr />
          <h5>Preguntas:</h5>
          <ul>
            {(preguntas[selectedEncuestaId] || []).map((preg) => (
              <li key={preg.id}>
                {preg.pregunta} - ({preg.tipo_respuesta})
                {preg.tipo_respuesta === 'opcion_multiple' && preg.alternativas ? (
                  <ul>
                    {preg.alternativas.map((alt, index) => (
                      <li key={index}>{alt}</li>
                    ))}
                  </ul>
                ) : null}
                <Button
                  variant="warning"
                  size="sm"
                  className="ms-2"
                  onClick={() =>
                    handleEditarPregunta(preg.id, preg.pregunta, preg.tipo_respuesta, preg.alternativas)
                  }
                >
                  Editar
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  className="ms-2"
                  onClick={() => handleEliminarPregunta(preg.id)}
                >
                  Eliminar
                </Button>
              </li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleShowModal('showPreguntaModal', false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EncuestasPage;