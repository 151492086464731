import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDocumentos, downloadDocumento } from '../redux/documentosSlice';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  Accordion,
  Button,
  Alert,
  Modal,
  Spinner,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';

// Configurar PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const DocumentosPage = () => {
  const dispatch = useDispatch();
  const { documentos, loading, error } = useSelector((state) => state.documentos);
  const [showError, setShowError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(null);
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    dispatch(fetchDocumentos());
  }, [dispatch]);

  useEffect(() => {
    if (error) setShowError(true);
  }, [error]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleRetry = () => {
    setShowError(false);
    dispatch(fetchDocumentos());
  };

  const handleDownload = (id) => {
    dispatch(downloadDocumento(id));
  };

  const handleView = (documento) => {
    setCurrentDocument(documento);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentDocument(null);
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Documentos</h2>

      {showError && (
        <Alert variant="danger" dismissible onClose={() => setShowError(false)}>
          <strong>Error:</strong> {error.message || 'Error al cargar documentos'}
          <div className="mt-2">
            <Button variant="secondary" onClick={handleRetry}>
              Intentar nuevamente
            </Button>
          </div>
        </Alert>
      )}

      {loading && !documentos.length && (
        <div className="text-center my-4">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Cargando...</span>
          </Spinner>
        </div>
      )}

      {!loading && documentos.length === 0 && !error && (
        <Alert variant="info">No hay documentos disponibles</Alert>
      )}

      <Accordion>
        {documentos.map((documento, index) => (
          <Accordion.Item eventKey={index.toString()} key={documento.id}>
            <Accordion.Header>
              <div className="d-flex justify-content-between w-100">
                <span>{documento.titulo || 'Documento sin título'}</span>
                <span className="text-muted small">
                  {new Date(documento.fecha_subida).toLocaleDateString()}
                </span>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="d-flex gap-2">
                <OverlayTrigger overlay={<Tooltip>Ver documento</Tooltip>}>
                  <Button
                    variant="outline-primary"
                    onClick={() => handleView(documento)}
                    disabled={loading}
                  >
                    <i className="bi bi-eye-fill"></i> Ver
                  </Button>
                </OverlayTrigger>

                <OverlayTrigger overlay={<Tooltip>Descargar documento</Tooltip>}>
                  <Button
                    variant="outline-success"
                    onClick={() => handleDownload(documento.id)}
                    disabled={loading}
                  >
                    <i className="bi bi-download"></i> Descargar
                  </Button>
                </OverlayTrigger>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>

      {/* Modal para visualización de PDF */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        size="lg"
        centered
        fullscreen="lg-down"
      >
        <Modal.Header closeButton>
          <Modal.Title>{currentDocument?.titulo || 'Documento'}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: '80vh', overflow: 'auto' }}>
          {currentDocument?.url && (
            <Document
              file={currentDocument.url}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={(error) => console.error('Error al cargar PDF:', error)}
            >
              {Array.from(new Array(numPages), (_, index) => (
                <Page 
                  key={`page_${index + 1}`} 
                  pageNumber={index + 1} 
                  width={600}
                />
              ))}
            </Document>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cerrar
          </Button>
          <Button 
            variant="primary" 
            onClick={() => currentDocument && handleDownload(currentDocument.id)}
          >
            Descargar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DocumentosPage;