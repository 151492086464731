import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  enviarCorreoMasivo,
  obtenerHistorialCorreos,
  eliminarCorreo,
} from "../../redux/correosSlice";
import { Button, Modal, Form, Table, Spinner, Alert, ProgressBar } from "react-bootstrap";
import api from "../../utils/axiosConfig";
import Swal from 'sweetalert2'; 

const CorreoMasivoPage = () => {
  const dispatch = useDispatch();
  const { correos, loading, error, successMessage } = useSelector((state) => state.correo);

  const [showModal, setShowModal] = useState(false);
  const [correoDetails, setCorreoDetails] = useState({
    asunto: "",
    mensaje: "",
    cc: "",
    bcc: "",
    adjuntos: [],
  });
  const [progress, setProgress] = useState(0);
  const [sending, setSending] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  // Estado para el modal del mensaje
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState("");

  // Efecto para mostrar el mensaje de éxito con SweetAlert2
  useEffect(() => {
    if (successMessage) {
      Swal.fire({
        title: "¡Éxito!",
        text: successMessage,
        icon: "success",
        confirmButtonText: "Aceptar",
      });
    }
  }, [successMessage]);

  // Efecto para mostrar el mensaje de error con SweetAlert2
  useEffect(() => {
    if (error) {
      Swal.fire({
        title: "Error",
        text: error,
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  }, [error]);

  // Efecto para obtener el historial de correos al cargar el componente
  useEffect(() => {
    dispatch(obtenerHistorialCorreos());
  }, [dispatch]);

  const handleCloseModal = () => {
    if (!sending) {
      setShowModal(false);
      setCorreoDetails({ asunto: "", mensaje: "", cc: "", bcc: "", adjuntos: [] });
      setValidationErrors({});
    }
  };

  // Función para abrir el modal del mensaje
  const handleShowMessageModal = (mensaje) => {
    setSelectedMessage(mensaje);
    setShowMessageModal(true);
  };

  // Función para cerrar el modal del mensaje
  const handleCloseMessageModal = () => {
    setShowMessageModal(false);
    setSelectedMessage("");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCorreoDetails({ ...correoDetails, [name]: value });
    validateField(name, value);
  };

  const handleFileUpload = (e) => {
    setCorreoDetails({ ...correoDetails, adjuntos: [...e.target.files] });
  };

  const validateEmailList = (emails) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailList = emails.split(';');
    const invalidEmails = emailList.filter(email => email.trim() && !emailRegex.test(email.trim()));
    return invalidEmails;
  };

  const validateField = (fieldName, value) => {
    let errors = { ...validationErrors };

    switch (fieldName) {
      case "asunto":
        if (!value) {
          errors.asunto = "El asunto es requerido";
        } else if (value.length > 100) {
          errors.asunto = "El asunto no puede tener más de 100 caracteres";
        } else {
          delete errors.asunto;
        }
        break;
        case "mensaje":
          if (!value) {
            errors.mensaje = "El mensaje es requerido";
          } else if (value.length < 10 || value.length > 10000) { 
            errors.mensaje = "El mensaje debe tener entre 10 y 10,000 caracteres";
          } else {
            delete errors.mensaje;
          }
          break;
      case "cc":
        if (value) {
          const invalidEmails = validateEmailList(value);
          if (invalidEmails.length > 0) {
            errors.cc = `Los siguientes correos en CC son inválidos: ${invalidEmails.join(', ')}. Por favor, ingrese las direcciones de correo electrónico separadas por punto y coma (;). Ejemplo: correo1@sinterec.cl;correo2@sinterec.cl`;
          } else {
            delete errors.cc;
          }
        } else {
          delete errors.cc;
        }
        break;
      case "bcc":
        if (value) {
          const invalidEmails = validateEmailList(value);
          if (invalidEmails.length > 0) {
            errors.bcc = `Los siguientes correos en BCC son inválidos: ${invalidEmails.join(', ')}. Por favor, ingrese las direcciones de correo electrónico separadas por punto y coma (;). Ejemplo: correo1@sinterec.cl;correo2@sinterec.cl`;
          } else {
            delete errors.bcc;
          }
        } else {
          delete errors.bcc;
        }
        break;
      default:
        break;
    }

    setValidationErrors(errors);
  };

  const handleSendCorreo = async () => {
    if (Object.keys(validationErrors).length > 0) {
      Swal.fire({
        title: "Error",
        text: "Por favor, corrige los errores antes de enviar el correo.",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
      return;
    }

    if (!correoDetails.asunto || !correoDetails.mensaje || correoDetails.mensaje.length <= 10) {
      Swal.fire({
        title: "Error",
        text: "Completa los campos correctamente.",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
      return;
    }

    try {
      setSending(true);
      setProgress(0);

      const formData = new FormData();
      formData.append("asunto", correoDetails.asunto);
      formData.append("mensaje", correoDetails.mensaje);
      formData.append("cc", correoDetails.cc || null);
      formData.append("bcc", correoDetails.bcc || null);

      if (correoDetails.adjuntos && correoDetails.adjuntos.length > 0) {
        correoDetails.adjuntos.forEach((file) => {
          formData.append("adjuntos", file);
        });
      }

      // Enviar el correo
      await dispatch(enviarCorreoMasivo(formData)).unwrap();

      // Obtener el progreso del envío
      const interval = setInterval(async () => {
        try {
          const response = await api.get("/api/correo/progreso");
          const { total, completed } = response.data;

          if (total === 0) {
            clearInterval(interval);
            setSending(false);
            return;
          }

          const progressPercentage = Math.round((completed / total) * 100);
          setProgress(progressPercentage);

          if (completed >= total) {
            clearInterval(interval);
            setSending(false);
            dispatch(obtenerHistorialCorreos());
            handleCloseModal();
          }
        } catch (err) {
          console.error("Error al obtener el progreso del envío:", err);
          clearInterval(interval);
          setSending(false);
        }
      }, 1000);
    } catch (err) {
      console.error("Error al enviar el correo:", err);
      Swal.fire({
        title: "Error",
        text: "Ocurrió un error al enviar el correo. Por favor, inténtalo de nuevo.",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
      setSending(false);
    }
  };

  return (
    <div>
      <h1>Gestión de Correos Masivos</h1>

      <Button onClick={() => setShowModal(true)} className="mb-3">
        Enviar Correo Masivo
      </Button>

      {loading && <Spinner animation="border" />}
      {error && <Alert variant="danger">{error}</Alert>}

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Asunto</th>
            <th>Mensaje</th>
            <th>Estado</th>
            <th>Fecha de Envío</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {correos.map((correo, index) => (
            <tr key={correo.id}>
              <td>{index + 1}</td>
              <td>{correo.asunto}</td>
              <td>
                {correo.mensaje.length > 50 ? (
                  <>
                    {correo.mensaje.substring(0, 50)}...
                    <Button
                      variant="link"
                      onClick={() => handleShowMessageModal(correo.mensaje)}
                    >
                      Ver más
                    </Button>
                  </>
                ) : (
                  correo.mensaje
                )}
              </td>
              <td>{correo.estado}</td>
              <td>{new Date(correo.fecha_envio).toLocaleString("es-CL")}</td>
              <td>
                <Button variant="danger" onClick={() => dispatch(eliminarCorreo(correo.id))}>
                  Eliminar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal para enviar correo masivo */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Enviar Correo Masivo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Asunto</Form.Label>
              <Form.Control
                type="text"
                name="asunto"
                value={correoDetails.asunto}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.asunto}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.asunto}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Mensaje</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                name="mensaje"
                value={correoDetails.mensaje}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.mensaje}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.mensaje}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>CC</Form.Label>
              <Form.Control
                type="text"
                name="cc"
                value={correoDetails.cc}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.cc}
                placeholder="Ingrese correos separados por punto y coma (;). Ejemplo: correo1@sinterec.cl;correo2@sinterec.cl"
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.cc}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>BCC</Form.Label>
              <Form.Control
                type="text"
                name="bcc"
                value={correoDetails.bcc}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.bcc}
                placeholder="Ingrese correos separados por punto y coma (;). Ejemplo: correo1@sinterec.cl;correo2@sinterec.cl"
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.bcc}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Adjuntar Archivos</Form.Label>
              <Form.Control type="file" multiple onChange={handleFileUpload} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal} disabled={sending}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSendCorreo} disabled={sending}>
            {sending ? (
              <>
                <Spinner as="span" size="sm" animation="border" role="status" aria-hidden="true" />
                {" Enviando..."}
              </>
            ) : (
              "Enviar"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para mostrar el mensaje completo */}
      <Modal show={showMessageModal} onHide={handleCloseMessageModal}>
        <Modal.Header closeButton>
          <Modal.Title>Mensaje Completo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{selectedMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseMessageModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      {sending && (
        <div className="mt-3">
          <ProgressBar animated now={progress} label={`${progress}%`} />
          <p className="text-center mt-2">Enviando correos... {progress}% completado</p>
        </div>
      )}
    </div>
  );
};

export default CorreoMasivoPage;