import React, { useState } from "react";
import { Offcanvas, Button, ListGroup, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

const AdminPanel = () => {
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const linkStyle = {
    color: "#d0021b", // Color rojo del logo
    textDecoration: "none",
    fontWeight: "bold",
  };

  const hoverStyle = {
    color: "#a00115", // Color más oscuro para hover
  };

  // Lista de enlaces ordenada alfabéticamente
  const adminLinks = [
    { to: "/admin/beneficios", text: "Gestión de Beneficios" },
    { to: "/admin/carga-masiva-usuarios", text: "Carga Masiva de Usuarios" },
    { to: "/admin/convenios", text: "Gestión de Convenios" },
    { to: "/admin/correos", text: "Correo Masivo" },
    { to: "/admin/dashboard", text: "Dashboard General" },
    { to: "/admin/directiva", text: "Administrar Directiva del Sindicato" },
    { to: "/admin/documentos", text: "Gestión de Documentos" },
    { to: "/admin/encuestas", text: "Gestión de Encuestas" },
    { to: "/admin/encuestas/resultados", text: "Ver Resultados de Encuestas" }, // Nuevo enlace
    { to: "/admin/exportar-usuarios", text: "Exportar Usuarios" },
    { to: "/admin/noticias", text: "Gestión de Noticias" },
    { to: "/admin/usuarios", text: "Gestión de Usuarios" },
  ];

  // Ordenar enlaces alfabéticamente
  const sortedLinks = adminLinks.sort((a, b) => a.text.localeCompare(b.text));

  // Filtrar enlaces basados en el término de búsqueda
  const filteredLinks = sortedLinks.filter((link) =>
    link.text.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      {/* Botón del Panel de Administración */}
      <Button
        onClick={handleShow}
        style={{
          backgroundColor: "#d0021b", // Color rojo del logo
          border: "none",
          fontWeight: "bold",
          textTransform: "uppercase",
          padding: "8px 16px",
        }}
      >
        Panel de Administración
      </Button>

      {/* Offcanvas para el Panel */}
      <Offcanvas show={show} onHide={handleClose} placement="end" style={{ width: "300px" }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title
            style={{
              color: "#d0021b", // Color rojo del logo
              fontWeight: "bold",
              textTransform: "uppercase",
              fontSize: "1.5rem",
            }}
          >
            Panel de Administración
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* Campo de búsqueda */}
          <Form.Control
            type="text"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              marginBottom: "1rem",
              borderColor: "#d0021b",
              borderRadius: "4px",
            }}
          />

          {/* Lista de enlaces */}
          <ListGroup variant="flush">
            {filteredLinks.map((link, index) => (
              <ListGroup.Item key={index} className="px-3 py-2" onClick={handleClose}>
                <Link
                  to={link.to}
                  style={linkStyle}
                  onMouseEnter={(e) => (e.target.style.color = hoverStyle.color)}
                  onMouseLeave={(e) => (e.target.style.color = linkStyle.color)}
                >
                  {link.text}
                </Link>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Offcanvas.Body>
      </Offcanvas>

      
    </>
  );
};

export default AdminPanel;