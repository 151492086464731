import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const PrivacyPolicyPage = () => {
    return (
        <Container className="my-5">
            <Row className="justify-content-center">
                <Col md={10}>
                    <Card className="shadow-lg">
                        <Card.Body>
                            <h1 className="text-center text-primary mb-4">Política de Privacidad</h1>
                            <p className="lead">
                                En nuestro sindicato, valoramos y protegemos tu privacidad. Esta política explica 
                                cómo cuidamos tu información personal.
                            </p>

                            <h3 className="mt-4">1. Información que Recopilamos</h3>
                            <p>
                                Solo solicitamos los datos necesarios para:
                            </p>
                            <ul>
                                <li>Identificarte como miembro del sindicato</li>
                                <li>Mantenerte informado sobre beneficios y actividades</li>
                                <li>Gestionar tu participación en encuestas y votaciones</li>
                                <li>Proveer asistencia cuando nos contactas</li>
                            </ul>

                            <h3 className="mt-4">2. Cómo Protegemos tus Datos</h3>
                            <p>
                                Implementamos medidas de seguridad avanzadas:
                            </p>
                            <ul>
                                <li><strong>Conexiones seguras:</strong> Todos los datos se transmiten encriptados</li>
                                <li><strong>Datos cifrados/encriptados</strong> Todos los datos estan cifrados/encriptados</li>
                                <li><strong>Acceso restringido:</strong> Solo personal autorizado puede ver tu información</li>
                                <li><strong>Protección contra hackeos:</strong> Sistemas que bloquean intentos de acceso no autorizado</li>
                                <li><strong>Contraseñas seguras:</strong> Tus credenciales están protegidas con tecnología avanzada</li>
                            </ul>

                            <h3 className="mt-4">3. Uso de tu Información</h3>
                            <p>
                                Tus datos se usan exclusivamente para:
                            </p>
                            <ul>
                                <li>Comunicaciones del sindicato</li>
                                <li>Gestión de beneficios y servicios</li>
                                <li>Notificaciones importantes</li>
                                <li>Mejorar nuestros servicios</li>
                            </ul>
                            <p>
                                <strong>Nunca</strong> compartimos tu información con terceros sin tu consentimiento.
                            </p>

                            <h3 className="mt-4">4. Tus Derechos</h3>
                            <p>
                                Tienes derecho a:
                            </p>
                            <ul>
                                <li>Solicitar una copia de tus datos personales</li>
                                <li>Corregir información incorrecta</li>
                                <li>Obtener información clara sobre cómo usamos tus datos</li>
                            </ul>

                            

                            <p className="text-center mt-4 text-muted">
                                <small><strong>Última actualización:</strong> 27 de marzo de 2025</small>
                            </p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default PrivacyPolicyPage;