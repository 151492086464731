import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/authSlice';
import api from '../../utils/axiosConfig';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { Eye, EyeSlash } from 'react-bootstrap-icons';

const LoginComponent = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // Estado para mostrar/ocultar contraseña
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Validación de email
  const validateEmail = () => {
    if (!email) {
      setErrors((prev) => ({ ...prev, email: 'El email es requerido.' }));
      return false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setErrors((prev) => ({ ...prev, email: 'El email no es válido.' }));
      return false;
    }
    setErrors((prev) => ({ ...prev, email: null }));
    return true;
  };

  // Validación de contraseña (simplificada para no ser intrusiva durante el login)
  const validatePassword = () => {
    if (!password) {
      setErrors((prev) => ({ ...prev, password: 'La contraseña es requerida.' }));
      return false;
    }
    setErrors((prev) => ({ ...prev, password: null }));
    return true;
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    const isEmailValid = validateEmail();
    const isPasswordValid = validatePassword();

    if (!isEmailValid || !isPasswordValid) {
      setAlert({ type: 'danger', message: 'Por favor, corrige los errores en el formulario.' });
      return;
    }

    setIsLoading(true);
    try {
      const response = await api.post('/auth/login', { email, password });
      const { token, rol, nombre, apellido, cambio_clave } = response.data;

      dispatch(login({ token, rol, nombre, apellido }));
      localStorage.setItem('token', token);
      // ... resto del código de login

      if (cambio_clave) {
        navigate('/cambio-clave');
        return;
      }

      navigate('/');
    } catch (error) {
      // Manejo de errores
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {alert && (
        <Alert variant={alert.type} onClose={() => setAlert(null)} dismissible>
          {alert.message}
        </Alert>
      )}

      <Form onSubmit={handleLogin} noValidate>
        {/* Email Input */}
        <Form.Group controlId="formEmail" className="mb-3">
          <Form.Label>Correo Electrónico</Form.Label>
          <Form.Control
            type="email"
            placeholder="Ingresa tu correo"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={validateEmail}
            isInvalid={!!errors.email}
            required
          />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        </Form.Group>

        {/* Password Input con toggle */}
        <Form.Group controlId="formPassword" className="mb-3">
          <Form.Label>Contraseña</Form.Label>
          <InputGroup>
            <Form.Control
              type={showPassword ? "text" : "password"}
              placeholder="Ingresa tu contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onBlur={validatePassword}
              isInvalid={!!errors.password}
              required
            />
            <InputGroup.Text 
              style={{ cursor: 'pointer' }}
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <EyeSlash /> : <Eye />}
            </InputGroup.Text>
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>

        <Button type="submit" variant="primary" disabled={isLoading} className="w-100">
          {isLoading ? 'Iniciando sesión...' : 'Iniciar Sesión'}
        </Button>
      </Form>
    </>
  );
};

export default LoginComponent;