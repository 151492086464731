import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';
import { Container, Alert, Card, ListGroup, Placeholder } from 'react-bootstrap';
import { fetchBeneficios } from '../redux/beneficiosSlice';

const BeneficiosPage = () => {
  const dispatch = useDispatch();
  const { beneficios, loading, error } = useSelector((state) => state.beneficios);

  useEffect(() => {
    dispatch(fetchBeneficios());
  }, [dispatch]);

  const formatFecha = (fecha) => {
    const date = new Date(fecha);
    if (isNaN(date.getTime())) {
      return "Fecha no válida";
    }
    return new Intl.DateTimeFormat("es-CL", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date);
  };

  return (
    <Container className="mt-4">
      <h1 className="mb-4 text-center">Nuestros Beneficios</h1>
      {loading && (
        <Card>
          <Card.Body>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={6} />
            </Placeholder>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
              <Placeholder xs={6} /> <Placeholder xs={8} />
            </Placeholder>
          </Card.Body>
        </Card>
      )}
      {error && <Alert variant="danger">{error}</Alert>}
      {!loading && !error && beneficios.length === 0 && (
        <Alert variant="info">No hay beneficios disponibles en este momento.</Alert>
      )}
      <Accordion defaultActiveKey="0" className="mb-3">
        {beneficios.map((beneficio, index) => (
          <Accordion.Item eventKey={index.toString()} key={beneficio.id} className="mb-3">
            <Accordion.Header>{beneficio.nombre}</Accordion.Header>
            <Accordion.Body>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <strong>Descripción:</strong> {beneficio.descripcion}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Fecha de Inicio:</strong> {formatFecha(beneficio.fecha_inicio)}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Fecha de Fin:</strong> {formatFecha(beneficio.fecha_fin)}
                </ListGroup.Item>
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </Container>
  );
};

export default BeneficiosPage;