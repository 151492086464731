import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Card, Button, Form, Modal, Alert, Placeholder } from 'react-bootstrap';
import {
    fetchDirectiva,
    createDirigente,
    updateDirigente,
    deleteDirigente,
} from '../../redux/directivaSlice';
import FileUpload from '../../components/FileUpload';
import Swal from 'sweetalert2'; // Importar SweetAlert2

const DirectivaAdminPage = () => {
    const dispatch = useDispatch();
    const { dirigentes, loading, error } = useSelector((state) => state.directiva);
    const [showModal, setShowModal] = useState(false);
    const [currentDirigente, setCurrentDirigente] = useState(null);
    const [formData, setFormData] = useState({
        nombre: '',
        cargo: '',
        email: '',
        imagen: null,
    });
    const [formErrors, setFormErrors] = useState({
        nombre: '',
        cargo: '',
        email: '',
        imagen: '',
    });
    const [showFormError, setShowFormError] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false); // Estado para controlar el envío del formulario

    useEffect(() => {
        dispatch(fetchDirectiva());
    }, [dispatch]);

    // Validaciones en tiempo real
    const validateField = (name, value) => {
        switch (name) {
            case 'nombre':
                if (!value.trim()) {
                    return 'El nombre no puede estar vacío';
                } else if (value.length < 3) {
                    return 'El nombre debe tener al menos 3 caracteres';
                } else if (value.length > 255) {
                    return 'El nombre no puede tener más de 255 caracteres';
                }
                return '';
            case 'cargo':
                if (!value.trim()) {
                    return 'El cargo no puede estar vacío';
                } else if (value.length < 3) {
                    return 'El cargo debe tener al menos 3 caracteres';
                } else if (value.length > 255) {
                    return 'El cargo no puede tener más de 255 caracteres';
                }
                return '';
            case 'email':
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                    return 'El email debe tener un formato válido';
                }
                return '';
            case 'imagen':
                if (!value) {
                    return 'La imagen es obligatoria';
                }
                return '';
            default:
                return '';
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setFormErrors({ ...formErrors, [name]: validateField(name, value) });
    };

    const handleFileChange = (file) => {
        setFormData({ ...formData, imagen: file });
        setFormErrors({ ...formErrors, imagen: validateField('imagen', file) });
    };

    // Verificar si el formulario es válido
    const isFormValid = () => {
        const areFieldsFilled =
            formData.nombre.trim() &&
            formData.cargo.trim() &&
            formData.email.trim() &&
            formData.imagen;

        const areFieldsValid = Object.values(formErrors).every((error) => !error);

        const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email);

        return areFieldsFilled && areFieldsValid && isEmailValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isFormValid()) {
            setShowFormError(true);
            // Mostrar SweetAlert2 si no se ha cargado una imagen
            if (!formData.imagen) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Debes cargar una imagen para continuar.',
                });
            }
            return;
        }

        setIsSubmitting(true); // Iniciar el estado de envío

        const form = new FormData();
        form.append('nombre', formData.nombre);
        form.append('cargo', formData.cargo);
        form.append('email', formData.email);
        if (formData.imagen) {
            form.append('imagen', formData.imagen);
        }

        try {
            if (currentDirigente) {
                await dispatch(updateDirigente({ id: currentDirigente.id, dirigenteData: form }));
                Swal.fire('Éxito', 'Dirigente actualizado correctamente', 'success');
            } else {
                await dispatch(createDirigente(form));
                Swal.fire('Éxito', 'Dirigente creado correctamente', 'success');
            }
            setShowModal(false);
            setFormData({ nombre: '', cargo: '', email: '', imagen: null });
            setCurrentDirigente(null);
            setShowFormError(false);
        } catch (err) {
            Swal.fire('Error', 'Error al procesar la solicitud', 'error');
        } finally {
            setIsSubmitting(false); // Finalizar el estado de envío
        }
    };

    const handleEdit = (dirigente) => {
        setCurrentDirigente(dirigente);
        setFormData({
            nombre: dirigente.nombre,
            cargo: dirigente.cargo,
            email: dirigente.email,
            imagen: null, // Reiniciar la imagen al editar
        });
        setFormErrors({
            nombre: '',
            cargo: '',
            email: '',
            imagen: '',
        });
        setShowModal(true);
        setShowFormError(false);
    };

    const handleDelete = async (id) => {
        const result = await Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        });

        if (result.isConfirmed) {
            try {
                await dispatch(deleteDirigente(id));
                Swal.fire('Éxito', 'Dirigente eliminado correctamente', 'success');
            } catch (err) {
                Swal.fire('Error', 'Error al eliminar el dirigente', 'error');
            }
        }
    };

    // Función para abrir el modal de agregar dirigente
    const handleAddDirigente = () => {
        setCurrentDirigente(null); // Reiniciar el estado del dirigente actual
        setFormData({ nombre: '', cargo: '', email: '', imagen: null }); // Reiniciar el formulario
        setFormErrors({ nombre: '', cargo: '', email: '', imagen: '' }); // Reiniciar los errores
        setShowModal(true); // Mostrar el modal
    };

    return (
        <Container className="mt-4">
            <h1 className="text-center mb-4">Administrar Directiva del Sindicato</h1>

            {/* Botón para agregar un nuevo dirigente */}
            <Button onClick={handleAddDirigente} className="mb-4">
                Agregar Dirigente
            </Button>

            {/* Manejo de errores */}
            {error && (
                <Alert variant="danger" className="mb-4">
                    Error: {error}
                </Alert>
            )}

            {/* Lista de dirigentes */}
            <Row className="gy-4">
                {loading ? (
                    // Placeholders de carga
                    Array.from({ length: 3 }).map((_, index) => (
                        <Col key={index} md={6} lg={4}>
                            <Card className="shadow-sm">
                                <Placeholder as={Card.Img} animation="glow" style={{ height: '200px' }} />
                                <Card.Body>
                                    <Placeholder as={Card.Title} animation="glow">
                                        <Placeholder xs={8} />
                                    </Placeholder>
                                    <Placeholder as={Card.Subtitle} animation="glow">
                                        <Placeholder xs={6} />
                                    </Placeholder>
                                    <Placeholder as={Card.Text} animation="glow">
                                        <Placeholder xs={10} />
                                    </Placeholder>
                                    <Placeholder as={Button} animation="glow" variant="warning" xs={6} />
                                    <Placeholder as={Button} animation="glow" variant="danger" xs={6} className="ms-2" />
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                ) : (
                    // Datos reales
                    dirigentes.map((dirigente) => (
                        <Col key={dirigente.id} md={6} lg={4}>
                            <Card className="shadow-sm">
                                <Card.Img variant="top" src={dirigente.imagen_url} alt={`Foto de ${dirigente.nombre}`} />
                                <Card.Body>
                                    <Card.Title>{dirigente.nombre}</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">{dirigente.cargo}</Card.Subtitle>
                                    <Card.Text>
                                        <strong>Email:</strong> {dirigente.email}
                                    </Card.Text>
                                    <Button variant="warning" onClick={() => handleEdit(dirigente)}>
                                        Editar
                                    </Button>
                                    <Button variant="danger" onClick={() => handleDelete(dirigente.id)} className="ms-2">
                                        Eliminar
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                )}
            </Row>

            {/* Modal para agregar/editar dirigentes */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{currentDirigente ? 'Editar Dirigente' : 'Agregar Dirigente'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        {showFormError && (
                            <Alert variant="danger" className="mb-3">
                                Por favor, corrige los errores antes de enviar el formulario.
                            </Alert>
                        )}

                        <Form.Group className="mb-3">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type="text"
                                name="nombre"
                                value={formData.nombre}
                                onChange={handleInputChange}
                                isInvalid={!!formErrors.nombre}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                {formErrors.nombre}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Cargo</Form.Label>
                            <Form.Control
                                type="text"
                                name="cargo"
                                value={formData.cargo}
                                onChange={handleInputChange}
                                isInvalid={!!formErrors.cargo}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                {formErrors.cargo}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                isInvalid={!!formErrors.email}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                {formErrors.email}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Imagen: <span className="text-danger">La imagen es obligatoria(*)</span></Form.Label>
                            <FileUpload onFileSelect={handleFileChange} />
                            {formErrors.imagen && <div className="text-danger">{formErrors.imagen}</div>}
                        </Form.Group>
                        <Button type="submit" variant="primary" disabled={isSubmitting}>
                            {isSubmitting
                                ? currentDirigente
                                    ? 'Actualizando...'
                                    : 'Agregando...'
                                : currentDirigente
                                ? 'Actualizar'
                                : 'Agregar'}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default DirectivaAdminPage;