import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchNoticias, fetchNoticiaById, clearNoticiaSeleccionada } from '../redux/noticiasSlice';
import { Container, Row, Col, Card, Button, Placeholder } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Efecto de carga de imagen

const NoticiasPage = () => {
    const dispatch = useDispatch();

    // Acceso al estado global
    const { noticias, noticiaSeleccionada, loading, error } = useSelector((state) => state.noticias);

    // Estado local para controlar el detalle de la noticia
    const [mostrarDetalle, setMostrarDetalle] = useState(false);

    // Cargar noticias al montar el componente
    useEffect(() => {
        dispatch(fetchNoticias());
    }, [dispatch]);

    // Manejar clic en una noticia para mostrar detalles
    const handleVerDetalles = (id) => {
        dispatch(fetchNoticiaById(id));
        setMostrarDetalle(true);
    };

    // Manejar clic para volver a la lista de noticias
    const handleVolver = () => {
        setMostrarDetalle(false);
        dispatch(clearNoticiaSeleccionada());
    };

    // Función para obtener una imagen predeterminada relacionada con noticias
    const obtenerImagenPredeterminada = () => {
        const ancho = 400; // Ancho de la imagen
        const alto = 200; // Alto de la imagen
        return `https://picsum.photos/${ancho}/${alto}?grayscale`; // Imagen en escala de grises
    };

    return (
        <Container className="my-5">
            <h1 className="text-center mb-4">Noticias</h1>
            {error && <p className="text-danger text-center">Error: {error}</p>}

            {!loading && noticias.length === 0 && (
                <p className="text-center">No hay noticias disponibles en este momento.</p>
            )}

            {loading ? (
                // Mostrar placeholders de carga
                <Row>
                    {Array.from({ length: 6 }).map((_, index) => (
                        <Col key={index} xs={12} md={6} lg={4} className="mb-4">
                            <Card>
                                <div style={{ height: '200px', backgroundColor: '#f0f0f0' }}>
                                    <Placeholder as={LazyLoadImage} animation="glow" style={{ height: '100%', width: '100%' }} />
                                </div>
                                <Card.Body>
                                    <Placeholder as={Card.Title} animation="glow">
                                        <Placeholder xs={8} />
                                    </Placeholder>
                                    <Placeholder as={Card.Text} animation="glow">
                                        <Placeholder xs={10} /> <Placeholder xs={6} /> <Placeholder xs={8} />
                                    </Placeholder>
                                    <Placeholder as={Button} animation="glow" variant="primary" xs={6} />
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            ) : (
                // Mostrar datos reales cuando no esté cargando
                !mostrarDetalle && noticias.length > 0 && (
                    <Row>
                        {noticias.map((noticia) => (
                            <Col key={noticia.id} xs={12} md={6} lg={4} className="mb-4">
                                <Card>
                                    <LazyLoadImage
                                        src={noticia.imagen_url || obtenerImagenPredeterminada()}
                                        alt={noticia.titulo}
                                        effect="blur" // Efecto de carga
                                        style={{ height: '200px', objectFit: 'cover', width: '100%' }}
                                    />
                                    <Card.Body>
                                        <Card.Title>{noticia.titulo}</Card.Title>
                                        <Card.Text>
                                            {noticia.descripcion.substring(0, 100)}...
                                        </Card.Text>
                                        <Button variant="primary" onClick={() => handleVerDetalles(noticia.id)}>
                                            Ver más
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                )
            )}

            {mostrarDetalle && noticiaSeleccionada && (
                <div className="text-center">
                    <Button variant="success" className="mb-4" onClick={handleVolver}>
                        Volver
                    </Button>
                    <Card>
                        <LazyLoadImage
                            src={
                                noticiaSeleccionada.imagen_url || obtenerImagenPredeterminada()
                            }
                            alt={noticiaSeleccionada.titulo}
                            effect="blur" // Efecto de carga
                            style={{ height: '400px', objectFit: 'cover', width: '100%' }}
                        />
                        <Card.Body>
                            <Card.Title>{noticiaSeleccionada.titulo}</Card.Title>
                            <Card.Text>{noticiaSeleccionada.descripcion}</Card.Text>
                            <Card.Text className="text-muted">
                                Publicada el: {noticiaSeleccionada.fecha_publicacion}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            )}
        </Container>
    );
};

export default NoticiasPage;