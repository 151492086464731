import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBeneficios, createBeneficio, deleteBeneficio } from "../../redux/beneficiosSlice";
import { Button, Modal, Form, Table, Alert, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";

const BeneficiosAdminPage = () => {
  const dispatch = useDispatch();
  const { beneficios, loading, error } = useSelector((state) => state.beneficios);

  const [showModal, setShowModal] = useState(false);
  const [currentBeneficio, setCurrentBeneficio] = useState({
    nombre: "",
    descripcion: "",
    fecha_inicio: "",
    fecha_fin: "",
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [deleteError, setDeleteError] = useState(null);

  const validateField = (name, value) => {
    switch (name) {
      case "nombre":
        if (value.length < 2) return "El nombre debe tener un mínimo de 2 caracteres.";
        if (!value) return "El nombre es obligatorio.";
        if (value.length > 255) return "El nombre debe tener un máximo de 255 caracteres.";
        return "";
      case "descripcion":
        if (value && value.length < 10) return "La descripción debe tener al menos 10 caracteres.";
        return "";
      case "fecha_inicio":
        if (!value) return "La fecha de inicio es obligatoria.";
        const fechaInicio = new Date(value);
        const hoy = new Date();
        hoy.setHours(0, 0, 0, 0);
        if (fechaInicio.toString() === "Invalid Date") return "La fecha de inicio no es válida.";
        if (fechaInicio < hoy) return "La fecha de inicio no puede ser en el pasado.";
        return "";
      case "fecha_fin":
        if (value && new Date(value).toString() === "Invalid Date") return "La fecha de fin no es válida.";
        if (value && new Date(value) <= new Date(currentBeneficio.fecha_inicio)) {
          return "La fecha de fin debe ser posterior a la fecha de inicio.";
        }
        return "";
      default:
        return "";
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentBeneficio((prev) => ({ ...prev, [name]: value }));
    setValidationErrors((prev) => ({ ...prev, [name]: validateField(name, value) }));
  };

  const isFormValid = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const fechaInicio = new Date(currentBeneficio.fecha_inicio);
    fechaInicio.setHours(0, 0, 0, 0);

    const fechaFin = currentBeneficio.fecha_fin ? new Date(currentBeneficio.fecha_fin) : null;
    if (fechaFin) {
      fechaFin.setHours(0, 0, 0, 0);
    }

    return (
      currentBeneficio.nombre &&
      currentBeneficio.nombre.length > 2 &&
      currentBeneficio.nombre.length <= 255 &&
      (!currentBeneficio.descripcion || currentBeneficio.descripcion.length >= 10) &&
      currentBeneficio.fecha_inicio &&
      fechaInicio >= today &&
      (!fechaFin || fechaFin > fechaInicio) &&
      Object.values(validationErrors).every((error) => !error)
    );
  };

  const handleClose = () => {
    setShowModal(false);
    setValidationErrors({});
    setCurrentBeneficio({
      nombre: "",
      descripcion: "",
      fecha_inicio: "",
      fecha_fin: "",
    });
  };

  const handleShow = () => {
    setShowModal(true);
  };

  const handleCreateBeneficio = async () => {
    setIsSaving(true);

    try {
      await dispatch(createBeneficio(currentBeneficio)).unwrap();
      dispatch(fetchBeneficios());
      handleClose();
      Swal.fire("Éxito", "Beneficio creado correctamente", "success");
    } catch (error) {
      console.error("Error al crear el beneficio:", error);
      Swal.fire("Error", "Error al crear el beneficio. Por favor, inténtalo nuevamente.", "error");
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeleteBeneficio = async (id) => {
    setDeleteError(null);
    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "No podrás revertir esta acción",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar",
    });

    if (result.isConfirmed) {
      try {
        await dispatch(deleteBeneficio(id)).unwrap();
        dispatch(fetchBeneficios());
        Swal.fire("Eliminado", "El beneficio ha sido eliminado.", "success");
      } catch (error) {
        console.error("Error al eliminar el beneficio:", error);
        Swal.fire("Error", "Error al eliminar el beneficio. Por favor, inténtalo nuevamente.", "error");
      }
    }
  };

  const formatFecha = (fecha) => {
    const date = new Date(fecha);
    if (isNaN(date.getTime())) {
      return "Fecha no válida";
    }
    return new Intl.DateTimeFormat("es-CL", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date);
  };

  useEffect(() => {
    dispatch(fetchBeneficios());
  }, [dispatch]);

  return (
    <div>
      <h1>Gestión de Beneficios</h1>

      <Button onClick={handleShow} style={{ marginBottom: "20px" }}>
        Crear Nuevo Beneficio
      </Button>

      {loading ? (
        <p>Cargando beneficios...</p>
      ) : error ? (
        <Alert variant="danger">Error: {error}</Alert>
      ) : (
        <>
          {deleteError && <Alert variant="danger">{deleteError}</Alert>}
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th>Descripción</th>
                <th>Fecha Inicio</th>
                <th>Fecha Fin</th>
                <th>Activo</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {beneficios.map((beneficio) => (
                <tr key={beneficio.id}>
                  <td>{beneficio.id}</td>
                  <td>{beneficio.nombre}</td>
                  <td>{beneficio.descripcion}</td>
                  <td>{formatFecha(beneficio.fecha_inicio)}</td>
                  <td>{formatFecha(beneficio.fecha_fin)}</td>
                  <td>{beneficio.activo ? "Sí" : "No"}</td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDeleteBeneficio(beneficio.id)}
                    >
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Crear Beneficio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {validationErrors.general && (
            <Alert variant="danger">{validationErrors.general}</Alert>
          )}
          <Form>
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                name="nombre"
                value={currentBeneficio.nombre}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.nombre}
                placeholder="Ingrese el nombre del beneficio"
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.nombre}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                name="descripcion"
                value={currentBeneficio.descripcion}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.descripcion}
                placeholder="Ingrese la descripción del beneficio"
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.descripcion}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Fecha Inicio</Form.Label>
              <Form.Control
                type="date"
                name="fecha_inicio"
                value={currentBeneficio.fecha_inicio}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.fecha_inicio}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.fecha_inicio}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Fecha Fin</Form.Label>
              <Form.Control
                type="date"
                name="fecha_fin"
                value={currentBeneficio.fecha_fin}
                onChange={handleInputChange}
                isInvalid={!!validationErrors.fecha_fin}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.fecha_fin}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleCreateBeneficio} disabled={!isFormValid() || isSaving}>
            {isSaving ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Guardar"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default BeneficiosAdminPage;