import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Alert, Row, Col, Card } from "react-bootstrap";
import api from "../../utils/axiosConfig";
import { fetchRegiones, fetchComunasByRegion } from "../../redux/regionComunaSlice";

const RegisterUser = () => {
  const dispatch = useDispatch();
  const { regiones = [], comunas = [] } = useSelector((state) => state.regionComuna);

  useEffect(() => {
    dispatch(fetchRegiones());
  }, [dispatch]);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    nombre: "",
    primer_apellido: "",
    segundo_apellido: "",
    rut: "",
    dv: "",
    telefono: "",
    contacto_emergencia: "",
    telefono_emergencia: "",
    direccion: "",
    comuna_id: "",
    region_id: "",
    rol: "socio",
    fecha_nacimiento: "",
    fecha_ingreso: "",
    empresa: "claro",
  });

  const [passwordMatch, setPasswordMatch] = useState(true);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState([]);

  // Estado para errores por campo
  const [fieldErrors, setFieldErrors] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    nombre: "",
    primer_apellido: "",
    segundo_apellido: "",
    rut: "",
    dv: "",
    telefono: "",
    contacto_emergencia: "",
    telefono_emergencia: "",
    direccion: "",
    comuna_id: "",
    region_id: "",
    fecha_nacimiento: "",
    fecha_ingreso: "",
    empresa: "",
    rol: "",
  });

  const handleRegionChange = (e) => {
    const regionId = e.target.value || "";
    setFormData({
      ...formData,
      region_id: regionId,
      comuna_id: "",
    });

    if (regionId) {
      dispatch(fetchComunasByRegion(regionId));
    }
  };

  const validateField = (name, value) => {
    const newErrors = { ...fieldErrors };

    switch (name) {
      case "email":
        if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)) {
          newErrors.email = "El email no es válido.";
        } else {
          newErrors.email = "";
        }
        break;

      case "password":
        if (
          value.length < 8 ||
          !/[a-z]/.test(value) ||
          !/[A-Z]/.test(value) ||
          !/[0-9]/.test(value) ||
          !/[!@#$%^&*(),.?":{}|<>]/.test(value)
        ) {
          newErrors.password =
            "La contraseña debe tener al menos 8 caracteres, incluir una letra mayúscula, una minúscula, un número y un carácter especial.";
        } else {
          newErrors.password = "";
        }
        break;

      case "confirmPassword":
        if (value !== formData.password) {
          newErrors.confirmPassword = "Las contraseñas no coinciden.";
        } else {
          newErrors.confirmPassword = "";
        }
        break;

      case "nombre":
        if (!/^[a-zA-Z]+$/.test(value)) {
          newErrors.nombre = "El nombre solo puede contener letras.";
        } else {
          newErrors.nombre = "";
        }
        break;

      case "primer_apellido":
        if (!/^[a-zA-Z]+$/.test(value)) {
          newErrors.primer_apellido = "El primer apellido solo puede contener letras.";
        } else {
          newErrors.primer_apellido = "";
        }
        break;

      case "segundo_apellido":
        if (!/^[a-zA-Z]+$/.test(value)) {
          newErrors.segundo_apellido = "El segundo apellido solo puede contener letras.";
        } else {
          newErrors.segundo_apellido = "";
        }
        break;

      case "rut":
        if (!/^[0-9]{1,8}$/.test(value)) {
          newErrors.rut = "El RUT debe tener entre 1 y 8 dígitos.";
        } else {
          newErrors.rut = "";
        }
        break;

      case "dv":
        if (!/^[0-9kK]$/.test(value)) {
          newErrors.dv = "El dígito verificador debe ser un número del 0 al 9 o la letra k/K.";
        } else {
          newErrors.dv = "";
        }
        break;

      case "telefono":
        if (!/^\+56\d{8,9}$/.test(value)) {
          newErrors.telefono = "El teléfono debe ser un número válido para Chile (formato +56XXXXXXXX).";
        } else {
          newErrors.telefono = "";
        }
        break;

      case "contacto_emergencia":
        if (value && typeof value !== "string") {
          newErrors.contacto_emergencia = "El contacto de emergencia debe ser un texto válido.";
        } else {
          newErrors.contacto_emergencia = "";
        }
        break;

      case "telefono_emergencia":
        if (value && !/^\+56\d{8,9}$/.test(value)) {
          newErrors.telefono_emergencia = "El teléfono de emergencia debe ser un número válido para Chile (formato +56XXXXXXXX).";
        } else {
          newErrors.telefono_emergencia = "";
        }
        break;

      case "direccion":
        if (value && value.length > 255) {
          newErrors.direccion = "La dirección no puede exceder los 255 caracteres.";
        } else {
          newErrors.direccion = "";
        }
        break;

      case "comuna_id":
        if (!/^\d+$/.test(value)) {
          newErrors.comuna_id = "La comuna es obligatoria y debe ser un número entero válido.";
        } else {
          newErrors.comuna_id = "";
        }
        break;

      case "region_id":
        if (!value) {
          newErrors.region_id = "Debe seleccionar una región.";
        } else {
          newErrors.region_id = "";
        }
        break;

      case "fecha_nacimiento":
        const fechaNacimiento = new Date(value);
        const today = new Date();
        let age = today.getFullYear() - fechaNacimiento.getFullYear();
        const month = today.getMonth() - fechaNacimiento.getMonth();
        if (month < 0 || (month === 0 && today.getDate() < fechaNacimiento.getDate())) {
          age--;
        }

        if (isNaN(fechaNacimiento.getTime())) {
          newErrors.fecha_nacimiento = "La fecha de nacimiento debe ser válida.";
        } else if (fechaNacimiento > today) {
          newErrors.fecha_nacimiento = "La fecha de nacimiento no puede ser futura.";
        } else if (age < 18) {
          newErrors.fecha_nacimiento = "Debe tener al menos 18 años para registrarse.";
        } else {
          newErrors.fecha_nacimiento = "";
        }
        break;

      case "fecha_ingreso":
        const fechaIngreso = new Date(value);
        if (isNaN(fechaIngreso.getTime())) {
          newErrors.fecha_ingreso = "La fecha de ingreso debe ser válida.";
        } else if (fechaIngreso > today) {
          newErrors.fecha_ingreso = "La fecha de ingreso no puede ser futura.";
        } else {
          newErrors.fecha_ingreso = "";
        }
        break;

      case "empresa":
        if (!["claro", "vtr"].includes(value)) {
          newErrors.empresa = 'La empresa debe ser "claro" o "vtr".';
        } else {
          newErrors.empresa = "";
        }
        break;

      case "rol":
        if (!["administrador", "socio"].includes(value)) {
          newErrors.rol = 'El rol debe ser "administrador" o "socio".';
        } else {
          newErrors.rol = "";
        }
        break;

      default:
        break;
    }

    setFieldErrors(newErrors);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Actualiza el estado del formulario
    setFormData({ ...formData, [name]: value });

    // Valida el campo en tiempo real
    validateField(name, value);

    // Validación específica para confirmar contraseña
    if (name === "password" || name === "confirmPassword") {
      setPasswordMatch(
        name === "confirmPassword"
          ? value === formData.password
          : formData.confirmPassword === value
      );
    }
  };

  const validateForm = () => {
    const errors = [];
    const today = new Date();

    // Validación de fecha de nacimiento
    const fechaNacimiento = new Date(formData.fecha_nacimiento);
    let age = today.getFullYear() - fechaNacimiento.getFullYear();
    const month = today.getMonth() - fechaNacimiento.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < fechaNacimiento.getDate())) {
      age--;
    }

    if (isNaN(fechaNacimiento.getTime())) {
      errors.push("La fecha de nacimiento debe ser válida.");
    } else if (fechaNacimiento > today) {
      errors.push("La fecha de nacimiento no puede ser futura.");
    } else if (age < 18) {
      errors.push("Debe tener al menos 18 años para registrarse.");
    }

    // Validación de fecha de ingreso
    const fechaIngreso = new Date(formData.fecha_ingreso);
    if (isNaN(fechaIngreso.getTime())) {
      errors.push("La fecha de ingreso debe ser válida.");
    } else if (fechaIngreso > today) {
      errors.push("La fecha de ingreso no puede ser futura.");
    }

    // Validación de comuna y región
    if (!formData.region_id || !formData.comuna_id) {
      errors.push("Debe seleccionar una región y una comuna.");
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setErrors([]);

    const validationErrors = validateForm();
    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      return;
    }

    if (!passwordMatch) {
      setErrors([{ msg: "Las contraseñas no coinciden" }]);
      return;
    }

    // Asignar valores predeterminados si no se proporcionan
    const updatedFormData = {
      ...formData,
      contacto_emergencia: formData.contacto_emergencia || "No disponible",
      telefono_emergencia: formData.telefono_emergencia || "No disponible",
    };

    try {
      const response = await api.post("/auth/register", updatedFormData);
      if (response.status === 201) {
        setMessage("Usuario registrado con éxito");
        setFormData({
          email: "",
          password: "",
          confirmPassword: "",
          nombre: "",
          primer_apellido: "",
          segundo_apellido: "",
          rut: "",
          dv: "",
          telefono: "",
          contacto_emergencia: "",
          telefono_emergencia: "",
          direccion: "",
          comuna_id: "",
          region_id: "",
          rol: "socio",
          fecha_nacimiento: "",
          fecha_ingreso: "",
          empresa: "claro",
        });
        setPasswordMatch(true);
      }
    } catch (error) {
      console.error("Error inesperado al registrar usuario:", error);
      if (error.response && error.response.data.errors) {
        setErrors(error.response.data.errors);
      } else if (error.request) {
        setErrors([{ msg: "No se pudo conectar con el servidor" }]);
      } else {
        setErrors([{ msg: `Error inesperado: ${error.message}` }]);
      }
    }
  };

  return (
    <div>
      <h2>Registrar Usuario</h2>

      {/* Mensaje de éxito */}
      {message && <Alert variant="success">{message}</Alert>}

      {/* Mensajes de error */}
      {errors.length > 0 &&
        errors.map((err, index) => (
          <Alert key={index} variant="danger">
            {err.msg || "Error inesperado"}
          </Alert>
        ))}

      <Card>
        <Form onSubmit={handleSubmit}>
          <Row>
            {/* Columna izquierda */}
            <Col md={6}>
              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Correo electrónico"
                  value={formData.email}
                  onChange={handleChange}
                  isInvalid={!!fieldErrors.email}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {fieldErrors.email}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formRut" className="mb-3">
                <Form.Label>RUT</Form.Label>
                <Form.Control
                  type="text"
                  name="rut"
                  placeholder="12345678"
                  value={formData.rut}
                  onChange={handleChange}
                  isInvalid={!!fieldErrors.rut}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {fieldErrors.rut}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formDv" className="mb-3">
                <Form.Label>Dígito Verificador</Form.Label>
                <Form.Control
                  type="text"
                  name="dv"
                  placeholder="9"
                  value={formData.dv}
                  onChange={handleChange}
                  isInvalid={!!fieldErrors.dv}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {fieldErrors.dv}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formTelefono" className="mb-3">
                <Form.Label>Teléfono</Form.Label>
                <Form.Control
                  type="text"
                  name="telefono"
                  placeholder="+56912345678"
                  value={formData.telefono}
                  onChange={handleChange}
                  isInvalid={!!fieldErrors.telefono}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {fieldErrors.telefono}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formContactoEmergencia" className="mb-3">
                <Form.Label>Contacto de Emergencia</Form.Label>
                <Form.Control
                  type="text"
                  name="contacto_emergencia"
                  placeholder="Nombre del contacto de emergencia"
                  value={formData.contacto_emergencia}
                  onChange={handleChange}
                  isInvalid={!!fieldErrors.contacto_emergencia}
                />
                <Form.Control.Feedback type="invalid">
                  {fieldErrors.contacto_emergencia}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formTelefonoEmergencia" className="mb-3">
                <Form.Label>Teléfono de Emergencia</Form.Label>
                <Form.Control
                  type="text"
                  name="telefono_emergencia"
                  placeholder="+56987654321"
                  value={formData.telefono_emergencia}
                  onChange={handleChange}
                  isInvalid={!!fieldErrors.telefono_emergencia}
                />
                <Form.Control.Feedback type="invalid">
                  {fieldErrors.telefono_emergencia}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formRegion" className="mb-3">
                <Form.Label>Región</Form.Label>
                <Form.Select
                  name="region_id"
                  value={formData.region_id || ""}
                  onChange={handleRegionChange}
                  isInvalid={!!fieldErrors.region_id}
                  required
                >
                  <option value="">Seleccione una región</option>
                  {Array.isArray(regiones) &&
                    regiones.map((region) => (
                      <option key={region.id} value={region.id}>
                        {region.nombre}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {fieldErrors.region_id}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formComuna" className="mb-3">
                <Form.Label>Comuna</Form.Label>
                <Form.Select
                  name="comuna_id"
                  value={formData.comuna_id || ""}
                  onChange={handleChange}
                  isInvalid={!!fieldErrors.comuna_id}
                  required
                  disabled={!formData.region_id || comunas.length === 0}
                >
                  <option value="">Seleccione una comuna</option>
                  {Array.isArray(comunas) &&
                    comunas.map((comuna) => (
                      <option key={comuna.id} value={comuna.id}>
                        {comuna.nombre}
                      </option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {fieldErrors.comuna_id}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formDireccion" className="mb-3">
                <Form.Label>Dirección</Form.Label>
                <Form.Control
                  type="text"
                  name="direccion"
                  placeholder="Ingrese su dirección"
                  value={formData.direccion}
                  onChange={handleChange}
                  isInvalid={!!fieldErrors.direccion}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {fieldErrors.direccion}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            {/* Columna derecha */}
            <Col md={6}>
              <Form.Group controlId="formPassword" className="mb-3">
                <Form.Label>Contraseña</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Contraseña"
                  value={formData.password}
                  onChange={handleChange}
                  isInvalid={!!fieldErrors.password}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {fieldErrors.password}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formConfirmPassword" className="mb-3">
                <Form.Label>Confirmar Contraseña</Form.Label>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirme la contraseña"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  isInvalid={!!fieldErrors.confirmPassword || !passwordMatch}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {fieldErrors.confirmPassword || (!passwordMatch && "Las contraseñas no coinciden")}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formNombre" className="mb-3">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  name="nombre"
                  placeholder="Juan"
                  value={formData.nombre}
                  onChange={handleChange}
                  isInvalid={!!fieldErrors.nombre}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {fieldErrors.nombre}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formPrimerApellido" className="mb-3">
                <Form.Label>Primer Apellido</Form.Label>
                <Form.Control
                  type="text"
                  name="primer_apellido"
                  placeholder="Perez"
                  value={formData.primer_apellido}
                  onChange={handleChange}
                  isInvalid={!!fieldErrors.primer_apellido}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {fieldErrors.primer_apellido}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formSegundoApellido" className="mb-3">
                <Form.Label>Segundo Apellido</Form.Label>
                <Form.Control
                  type="text"
                  name="segundo_apellido"
                  placeholder="Gomez"
                  value={formData.segundo_apellido}
                  onChange={handleChange}
                  isInvalid={!!fieldErrors.segundo_apellido}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {fieldErrors.segundo_apellido}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formFechaNacimiento" className="mb-3">
                <Form.Label>Fecha de Nacimiento</Form.Label>
                <Form.Control
                  type="date"
                  name="fecha_nacimiento"
                  value={formData.fecha_nacimiento}
                  onChange={handleChange}
                  isInvalid={!!fieldErrors.fecha_nacimiento}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {fieldErrors.fecha_nacimiento}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formFechaIngreso" className="mb-3">
                <Form.Label>Fecha de Ingreso</Form.Label>
                <Form.Control
                  type="date"
                  name="fecha_ingreso"
                  value={formData.fecha_ingreso}
                  onChange={handleChange}
                  isInvalid={!!fieldErrors.fecha_ingreso}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {fieldErrors.fecha_ingreso}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formEmpresa" className="mb-3">
                <Form.Label>Empresa</Form.Label>
                <Form.Select
                  name="empresa"
                  value={formData.empresa}
                  onChange={handleChange}
                  isInvalid={!!fieldErrors.empresa}
                  required
                >
                  <option value="claro">Claro</option>
                  <option value="vtr">VTR</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {fieldErrors.empresa}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          {/* Botones */}
          <Row className="mt-3">
            <Col>
              <Button variant="primary" type="submit" disabled={!passwordMatch || Object.values(fieldErrors).some((error) => error)}>
                Registrar
              </Button>
              <Button
                variant="secondary"
                type="button"
                onClick={() => {
                  setFormData({
                    email: "",
                    password: "",
                    confirmPassword: "",
                    nombre: "",
                    primer_apellido: "",
                    segundo_apellido: "",
                    rut: "",
                    dv: "",
                    telefono: "",
                    contacto_emergencia: "",
                    telefono_emergencia: "",
                    direccion: "",
                    comuna_id: "",
                    region_id: "",
                    rol: "socio",
                    fecha_nacimiento: "",
                    fecha_ingreso: "",
                    empresa: "claro",
                  });
                  setFieldErrors({});
                  setErrors([]);
                  setMessage("");
                }}
                className="ms-2"
              >
                Limpiar
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default RegisterUser;