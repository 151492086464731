import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Form, Alert, Spinner, Placeholder } from "react-bootstrap";
import Swal from 'sweetalert2';
import {
  fetchConvenios,
  createConvenio,
  editConvenio,
  deactivateConvenio,
} from "../../redux/conveniosSlice";

const ConveniosPage = () => {
  const dispatch = useDispatch();
  const { convenios, loading, error } = useSelector((state) => state.convenios);

  const [showModal, setShowModal] = useState(false);
  const [currentConvenio, setCurrentConvenio] = useState(null);
  const [formData, setFormData] = useState({ titulo: "", descripcion: "" });
  const [formErrors, setFormErrors] = useState({ titulo: "", descripcion: "" });
  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    dispatch(fetchConvenios());
  }, [dispatch]);

  const validateField = (name, value) => {
    if (name === "titulo") {
      if (value.length < 4) {
        return "El título debe tener más de 3 caracteres.";
      }
    } else if (name === "descripcion") {
      if (value.length < 11) {
        return "La descripción debe tener más de 10 caracteres.";
      }
    }
    return "";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setFormErrors((prev) => ({ ...prev, [name]: validateField(name, value) }));
  };

  const isFormValid = () => {
    return formData.titulo.length >= 4 && formData.descripcion.length >= 11 && !formErrors.titulo && !formErrors.descripcion;
  };

  const handleShowModal = (convenio = null) => {
    setCurrentConvenio(convenio);
    setFormData(convenio ? { titulo: convenio.titulo, descripcion: convenio.descripcion } : { titulo: "", descripcion: "" });
    setFormErrors({ titulo: "", descripcion: "" });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentConvenio(null);
    setFormData({ titulo: "", descripcion: "" });
    setFormErrors({ titulo: "", descripcion: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid()) return;

    setIsCreating(true);
    try {
      if (currentConvenio) {
        await dispatch(editConvenio({ id: currentConvenio.id, convenio: formData }));
        Swal.fire('Éxito', 'Convenio actualizado correctamente', 'success');
      } else {
        await dispatch(createConvenio(formData));
        Swal.fire('Éxito', 'Convenio creado correctamente', 'success');
      }
      handleCloseModal();
    } catch (error) {
      Swal.fire('Error', 'Hubo un problema al guardar el convenio', 'error');
    } finally {
      setIsCreating(false);
    }
  };

  const handleDeactivate = async (id) => {
    const result = await Swal.fire({
      title: '¿Está seguro?',
      text: "No podrá revertir esta acción",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar'
    });

    if (result.isConfirmed) {
      try {
        await dispatch(deactivateConvenio(id));
        Swal.fire('Eliminado', 'El convenio ha sido eliminado.', 'success');
      } catch (error) {
        Swal.fire('Error', 'Hubo un problema al eliminar el convenio', 'error');
      }
    }
  };

  return (
    <div className="container my-4">
      <h1 className="mb-4 text-center" style={{ color: "#d0021b" }}>Gestión de Convenios</h1>
      
      {error && <Alert variant="danger">{error}</Alert>}

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>ID</th>
            <th>Título</th>
            <th>Descripción</th>
            <th>Activo</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            // Placeholders de carga mientras se cargan los convenios
            [...Array(5)].map((_, index) => (
              <tr key={index}>
                <td><Placeholder as="div" animation="glow"><Placeholder xs={6} /></Placeholder></td>
                <td><Placeholder as="div" animation="glow"><Placeholder xs={8} /></Placeholder></td>
                <td><Placeholder as="div" animation="glow"><Placeholder xs={12} /></Placeholder></td>
                <td><Placeholder as="div" animation="glow"><Placeholder xs={4} /></Placeholder></td>
                <td>
                  <Placeholder as="div" animation="glow">
                    <Placeholder.Button variant="warning" xs={4} />{' '}
                    <Placeholder.Button variant="danger" xs={4} />
                  </Placeholder>
                </td>
              </tr>
            ))
          ) : convenios.length > 0 ? (
            convenios.map((convenio) => (
              <tr key={convenio.id}>
                <td>{convenio.id}</td>
                <td>{convenio.titulo}</td>
                <td>{convenio.descripcion}</td>
                <td>{convenio.activo ? "Sí" : "No"}</td>
                <td>
                  <Button variant="warning" size="sm" onClick={() => handleShowModal(convenio)}>Editar</Button>{' '}
                  <Button variant="danger" size="sm" onClick={() => handleDeactivate(convenio.id)}>Eliminar</Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center">No hay convenios disponibles.</td>
            </tr>
          )}
        </tbody>
      </Table>

      <Button variant="primary" onClick={() => handleShowModal()}>Agregar Convenio</Button>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{currentConvenio ? "Editar Convenio" : "Agregar Convenio"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formTitulo">
              <Form.Label>Título</Form.Label>
              <Form.Control
                type="text"
                name="titulo"
                value={formData.titulo}
                onChange={handleChange}
                required
                placeholder="Ingrese el título del convenio"
                isInvalid={!!formErrors.titulo}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.titulo}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formDescripcion">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="descripcion"
                value={formData.descripcion}
                onChange={handleChange}
                required
                placeholder="Ingrese la descripción del convenio"
                isInvalid={!!formErrors.descripcion}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.descripcion}
              </Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit" disabled={!isFormValid() || isCreating}>
              {isCreating ? (
                <>
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  Creando...
                </>
              ) : currentConvenio ? "Guardar Cambios" : "Crear Convenio"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ConveniosPage;