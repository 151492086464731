import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/axiosConfig'; // Asegúrate de que esta importación sea correcta

export const fetchNotificaciones = createAsyncThunk(
  'notificaciones/fetchNotificaciones',
  async (_, { rejectWithValue }) => {
    try {
      // Hacer la solicitud con Axios
      const response = await api.get('/api/notificaciones/getnotif', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      console.log("Respuesta de Axios:", response); // Imprime la respuesta completa

      // Verificar si la respuesta es un arreglo
      if (Array.isArray(response.data) && response.data.length === 0) {
        console.warn("No hay notificaciones disponibles.");
        return []; // Devuelve un arreglo vacío si no hay notificaciones
      }

      return response.data; // Axios ya parsea la respuesta, así que usamos response.data
    } catch (error) {
      console.error("Error al obtener notificaciones:", error);

      // Manejar errores de Axios
      if (error.response) {
        // El servidor respondió con un código de estado fuera del rango 2xx
        return rejectWithValue(error.response.data.error || 'Error en la solicitud');
      } else if (error.request) {
        // La solicitud fue hecha pero no se recibió respuesta
        return rejectWithValue('No se recibió respuesta del servidor');
      } else {
        // Algo más causó el error
        return rejectWithValue(error.message || 'Error desconocido');
      }
    }
  }
);

const notificacionesSlice = createSlice({
  name: 'notificaciones',
  initialState: { lista: [], status: 'idle', error: null },
  reducers: {
    agregarNotificacion: (state, action) => {
      state.lista.unshift(action.payload); // Agregar la notificación al inicio del array
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificaciones.pending, (state) => {
        state.status = 'loading';
        state.error = null; // Limpiar errores anteriores
      })
      .addCase(fetchNotificaciones.fulfilled, (state, action) => {
        state.lista = action.payload; // Asignar el arreglo vacío o con notificaciones
        state.status = 'succeeded';
      })
      .addCase(fetchNotificaciones.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Error desconocido al obtener notificaciones';
      });
  },
});

export const { agregarNotificacion } = notificacionesSlice.actions;
export default notificacionesSlice.reducer;