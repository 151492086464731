import { io } from 'socket.io-client';

// URL del backend (ajusta según tu entorno)
const BACKEND_URL = 'wss://back-end-sindicatoclaro2.onrender.com';

console.log('Conectando a Socket.IO en:', BACKEND_URL); // Depuración: URL del backend

// Crear la instancia de Socket.IO
const socket = io(BACKEND_URL, {
  autoConnect: false, // No conectar automáticamente
  withCredentials: true, // Enviar credenciales (cookies, tokens)
  auth: (cb) => {
    // Obtener el token del localStorage
    const token = localStorage.getItem('token');
    cb({ token }); // Enviar el token en el handshake
  },
});


export default socket;