import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../utils/axiosConfig";

// Acción asincrónica para subir el archivo Excel
export const uploadFile = createAsyncThunk(
  "cargaMasiva/uploadFile",
  async (file, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("archivo", file); // Cambia "archivoExcel" a "archivo"

      console.log("Enviando archivo al backend:", file.name);

      const response = await api.post("/api/carga-masiva", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Axios maneja el boundary automáticamente
        },
      });

      return response.data;
    } catch (error) {
      console.error("Error en la carga:", error.response?.data);
      return rejectWithValue(error.response?.data || { message: "Error al cargar el archivo." });
    }
  }
);


const cargaMasivaSlice = createSlice({
  name: "cargaMasiva",
  initialState: {
    loading: false,
    success: null,
    error: null,
    resultados: [],
    progress: 0, // Nuevo estado para el progreso
  },
  reducers: {
    clearMessages: (state) => {
      state.success = null;
      state.error = null;
      state.progress = 0; // Reiniciar el progreso al limpiar mensajes
    },
    setProgress: (state, action) => {
      state.progress = action.payload; // Actualizar el progreso
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadFile.pending, (state) => {
        state.loading = true;
        state.success = null;
        state.error = null;
        state.progress = 0; // Reiniciar el progreso al iniciar la carga
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.loading = false;
        state.success = "Carga masiva completada.";
        state.resultados = action.payload;
        state.progress = 100; // Completar el progreso
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Error al procesar el archivo.";
        state.progress = 0; // Reiniciar el progreso en caso de error
      });
  },
});

export const { clearMessages, setProgress } = cargaMasivaSlice.actions;
export default cargaMasivaSlice.reducer;