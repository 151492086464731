import React, { useEffect } from 'react'; // Importa useEffect
import { initializeAuth } from './redux/authSlice';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider, useDispatch } from 'react-redux'; // Importa useDispatch
import store from './redux/store';
import GlobalSpinner from './components/GlobalSpinner';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import GlobalError from './components/GlobalError';

// Importar páginas y componentes
import LoginPage from './pages/LoginPage';
import NoticiasPage from './pages/NoticiasPage';
import DirectivaSindicatoPage from './pages/DirectivaSindicatoPage';
import Layout from './components/Layout';
import CambioContrasenaPage from './pages/CambioContrasenaPage';
import Home from './pages/Home';
import DocumentosPage from './pages/DocumentosPage';
import ChangePassword from './components/Profile/ChangePassword';
import ConfiguracionPage from './pages/ConfiguracionPage';
import NotFoundPage from './pages/NotFoundPage';
import RencuestaPage from './pages/socios/RencuestaPage';
import BeneficiosPagePublic from './pages/BeneficiosPagePublic';
import ConveniosPagePub from './pages/ConveniosPagePub';
import RecoverPasswordComponent from './components/Auth/RecoverPasswordComponent';
import ResetPasswordComponent from './components/Auth/ResetPasswordComponent';
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import CargaMasivaUsuariosPage from './pages/admin/CargaMasivaUsuariosPage';
import ExportarUsuariosPage from './pages/admin/ExportarUsuariosPage';

// IMPORT PANEL ADMIN PAGES
import UsuariosPage from './pages/admin/UsuariosPage';
import NoticiasAdminPage from './pages/admin/NoticiasAdminPage';
import DocumentosAdminPage from './pages/admin/DocumentosAdminPage';
import BeneficiosPage from './pages/admin/BeneficiosPage';
import EncuestasPage from './pages/admin/EncuestasPage';
import CorreoMasivoPage from './pages/admin/CorreoMasivoPage';
import DashboardPage from './pages/admin/DashboardPage';
import ProtectedRoute from './components/ProtectedRoute';
import ProfilePage from './pages/ProfilePage';
import ConveniosPage from './pages/admin/ConveniosPage';
import DirectivaAdminPage from './pages/admin/DirectivaAdminPage';
import ResultadosEncuestaPage from './pages/admin/ResultadosEncuestaPage';

// Configuración de íconos FontAwesome
library.add(fab);

function App() {
  const dispatch = useDispatch(); // Obtén el dispatch

  // Llama a initializeAuth al cargar la aplicación
  useEffect(() => {
    dispatch(initializeAuth());
  }, [dispatch]);

  const handleFirstLoginPasswordChange = async ({ newPassword }) => {
    try {
      const response = await fetch('/auth/cambio-clave', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ newPassword }),
      });

      if (!response.ok) {
        throw new Error('No se pudo cambiar la contraseña');
      }
    } catch (error) {
      console.error('Error al cambiar la contraseña:', error);
      throw error;
    }
  };

  return (
    <Provider store={store}>
      <Router>
        <GlobalError />
        <GlobalSpinner />
        <Routes>
          <Route path="/" element={<Layout />}>
            {/* Rutas públicas */}
            <Route index element={<Home />} />
            <Route path="noticias" element={<NoticiasPage />} />
            <Route path="directiva-sindicato" element={<DirectivaSindicatoPage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="documentos" element={<DocumentosPage />} />
            <Route path="beneficios" element={<BeneficiosPagePublic />} />
            <Route path="convenios" element={<ConveniosPagePub />} />
            <Route path="/privacidad" element={<PrivacyPolicyPage />} />
            {/* Recuperacion de password routes */}
            <Route path="/recover-password" element={<RecoverPasswordComponent />} />
            <Route path="/reset-password/:token" element={<ResetPasswordComponent />} />

            {/* Rutas protegidas */}
            <Route
              path="/cambio-clave"
              element={
                <ProtectedRoute>
                  <CambioContrasenaPage onFirstLoginPasswordChange={handleFirstLoginPasswordChange} />
                </ProtectedRoute>
              }
            />
            <Route path="perfil/cambiar-clave" element={<ChangePassword />} />
            <Route
              path="profile"
              element={
                <ProtectedRoute>
                  <ProfilePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="configuracion"
              element={
                <ProtectedRoute>
                  <ConfiguracionPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="admin/usuarios"
              element={
                <ProtectedRoute>
                  <UsuariosPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="admin/noticias"
              element={
                <ProtectedRoute>
                  <NoticiasAdminPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="admin/documentos"
              element={
                <ProtectedRoute>
                  <DocumentosAdminPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="admin/beneficios"
              element={
                <ProtectedRoute>
                  <BeneficiosPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="admin/encuestas"
              element={
                <ProtectedRoute>
                  <EncuestasPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/encuestas/resultados"
              element={
                <ProtectedRoute>
                  <ResultadosEncuestaPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="admin/correos"
              element={
                <ProtectedRoute>
                  <CorreoMasivoPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="admin/dashboard"
              element={
                <ProtectedRoute>
                  <DashboardPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="encuestas"
              element={
                <ProtectedRoute>
                  <RencuestaPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="admin/convenios"
              element={
                <ProtectedRoute>
                  <ConveniosPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="admin/carga-masiva-usuarios"
              element={
                <ProtectedRoute>
                  <CargaMasivaUsuariosPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="admin/exportar-usuarios"
              element={
                <ProtectedRoute>
                  <ExportarUsuariosPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="admin/directiva"
              element={
                <ProtectedRoute>
                  <DirectivaAdminPage />
                </ProtectedRoute>
              }
            />

            {/* Ruta 404 */}
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;