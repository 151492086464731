import React, { useState, useEffect } from "react";
import { comunas } from "../../utils/comunas";
import { useDispatch, useSelector } from "react-redux";
import { uploadFile, clearMessages } from "../../redux/cargaMasivaSlice";
import FileUpload from "../../components/FileUpload";
import Notification from "../../components/Notification";
import {
  Button,
  Container,
  Row,
  Col,
  Table,
  Modal,
  Form,
  Spinner,
} from "react-bootstrap";
import Swal from 'sweetalert2';

const CargaMasivaUsuariosPage = () => {
  const dispatch = useDispatch();
  const { loading, success, error, resultados } = useSelector(
    (state) => state.cargaMasiva
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedComuna, setSelectedComuna] = useState(null);
  const [erroresTexto, setErroresTexto] = useState("");
  const [showErroresDetalles, setShowErroresDetalles] = useState(false);

  useEffect(() => {
    // Limpiar el estado de Redux al desmontar el componente
    return () => {
      dispatch(clearMessages());
    };
  }, [dispatch]);

  useEffect(() => {
    if (success || error) {
      setShowModal(true);

      // Formatear los errores para mostrarlos en el textarea
      if (resultados?.errores?.length > 0) {
        const erroresFormateados = resultados.errores.map((err, index) => {
          return `Error ${index + 1}: ${err}`;
        }).join("\n");
        setErroresTexto(erroresFormateados);
      }

      // Mostrar un mensaje detallado en lugar de usar Swal.fire
      if (success) {
        const mensajeExito = `
          ¡Carga masiva completada Exitosamente!\n
          Informe:\n
          Usuarios registrados: ${resultados.usuariosRegistrados.length}\n
          Errores: ${resultados.errores.length}\n
        `;
        setErroresTexto(mensajeExito);
      } else if (error) {
        setErroresTexto(`Error en la carga: ${error}`);
      }
    }
  }, [success, error, resultados, dispatch]);

  const handleUpload = () => {
    if (!selectedFile) {
      Swal.fire({
        icon: 'warning',
        title: 'Archivo no seleccionado',
        text: 'Por favor, selecciona un archivo Excel (.xlsx o .xls)',
      });
      return;
    }

    // Validación adicional por si el usuario modifica manualmente el input file
    const validExtensions = ['xlsx', 'xls'];
    const fileExtension = selectedFile.name.split('.').pop().toLowerCase();

    if (!validExtensions.includes(fileExtension)) {
      Swal.fire({
        icon: 'error',
        title: 'Tipo de archivo no válido',
        text: 'El archivo debe ser un Excel (.xlsx o .xls)',
        footer: 'Por favor, descarga la plantilla de ejemplo si necesitas ayuda con el formato'
      });
      return;
    }

    dispatch(uploadFile(selectedFile));
  };

  return (
    <Container className="mt-4">
      <h2 className="text-center">Carga Masiva de Usuarios</h2>
      <p className="text-muted text-center">
        Selecciona un archivo Excel con los usuarios a registrar. Asegúrate de
        que el formato sea correcto.
      </p>

      <Row className="justify-content-center">
        <Col md={8} className="text-center">
          <Notification message={error} type="danger" />
          <Notification message={success} type="success" />
          <FileUpload onFileSelect={setSelectedFile} />
          {selectedFile && (
            <div className="mt-2">
              <p className="text-success">
                <i className="bi bi-file-earmark-excel-fill me-2"></i>
                Archivo seleccionado: {selectedFile.name}
              </p>
              <p className="text-muted small">
                Tamaño: {(selectedFile.size / 1024).toFixed(2)} KB
              </p>
            </div>
          )}
          <Form.Group className="mt-3">
            <Form.Label>Revisa los id's de las comunas aca! :</Form.Label>
            <Form.Control
              as="select"
              value={selectedComuna ? selectedComuna.id : ""}
              onChange={(e) => {
                const comunaSeleccionada = comunas.find(c => c.id === parseInt(e.target.value));
                setSelectedComuna(comunaSeleccionada || null);
              }}
            >
              <option value="">Seleccione una Comuna</option>
              {comunas.map((comuna) => (
                <option key={comuna.id} value={comuna.id}>
                  {comuna.nombre} (ID: {comuna.id})
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Button
            variant={selectedFile ? "success" : "primary"}
            className="mt-3"
            onClick={handleUpload}
            disabled={loading || !selectedFile}
          >
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
                Procesando...
              </>
            ) : (
              <>
                <i className="bi bi-upload me-2"></i>
                {selectedFile ? `Subir ${selectedFile.name}` : "Selecciona un archivo primero"}
              </>
            )}
          </Button>
        </Col>
      </Row>

      {/* Modal con detalles del proceso */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Estado de la Carga</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading && <p>Subiendo archivo, por favor espera...</p>}

          {resultados?.usuariosRegistrados?.length > 0 && (
            <>
              <h5>Usuarios Registrados</h5>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Nombre</th>
                    <th>Primer Apellido</th>
                    <th>Segundo Apellido</th>
                    <th>Email</th>
                    <th>Empresa</th>
                    <th>Comuna</th>
                  </tr>
                </thead>
                <tbody>
                  {resultados.usuariosRegistrados.map((usuario, index) => {
                    const comuna = comunas.find(c => c.id === usuario.comuna_id);
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{usuario.nombre}</td>
                        <td>{usuario.primer_apellido}</td>
                        <td>{usuario.segundo_apellido}</td>
                        <td>{usuario.email}</td>
                        <td>{usuario.empresa}</td>
                        <td>{comuna ? comuna.nombre : "Desconocida"}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          )}

          {resultados?.errores?.length > 0 && (
            <>
              <h5>Errores en la Carga</h5>
              <p>Total de errores: {resultados.errores.length}</p>
              <Button
                variant="link"
                onClick={() => setShowErroresDetalles(!showErroresDetalles)}
              >
                {showErroresDetalles ? "Ocultar detalles" : "Ver más detalles"}
              </Button>

              {showErroresDetalles && (
                <Form.Group>
                  <Form.Label>Detalles de los errores:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    value={erroresTexto}
                    readOnly
                    style={{
                      fontFamily: "monospace",
                      fontSize: "14px",
                      textAlign: "left",
                      whiteSpace: "pre-wrap",
                      overflow: "auto",
                      resize: "none",
                      backgroundColor: "#f8f9fa",
                      border: "1px solid #ced4da",
                      borderRadius: "4px",
                      padding: "6px 12px",
                    }}
                  />
                </Form.Group>
              )}
            </>
          )}

          {success && resultados?.usuariosRegistrados?.length === 0 && (
            <p className="text-info">No se registraron usuarios. Revise los errores para más detalles.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Instrucciones para el usuario */}
      <Row className="mt-5">
        <Col>
          <h4>Formato del Archivo Excel</h4>
          <p>
            El archivo Excel debe tener los siguientes encabezados en la primera fila:
          </p>
          <Table striped bordered>
            <thead>
              <tr>
                <th>nombre</th>
                <th>primer_apellido</th>
                <th>segundo_apellido</th>
                <th>rut</th>
                <th>dv</th>
                <th>email</th>
                <th>telefono</th>
                <th>fecha_nacimiento</th>
                <th>empresa</th>
                <th>comuna_id</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Juan</td>
                <td>Pérez</td>
                <td>González</td>
                <td>12345678</td>
                <td>9</td>
                <td>juan@test.com</td>
                <td>+56912345678</td>
                <td>1990-05-10</td>
                <td>Claro</td>
                <td>121 (Puente Alto)</td>
              </tr>
            </tbody>
          </Table>
          <p>
            <strong>Nota:</strong> La columna <code>comuna_id</code> debe coincidir con el ID
            de la comuna registrada en la base de datos. Puedes ver los valores
            disponibles en la lista desplegable arriba.
          </p>

          {/* Enlace para descargar la plantilla de ejemplo */}
          <p>
            <strong>Descarga la plantilla de ejemplo:</strong>{" "}
            <a
              href="https://api.sinterec.cl/public/docs/FormatoCargaMasiva.xlsx"
              download="FormatoCargaMasiva.xlsx"
              className="btn btn-success"
            >
              Descargar Plantilla
            </a>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default CargaMasivaUsuariosPage;