import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDashboardStats } from '../../redux/dashboardSlice';
import { Card, Row, Col, Alert, Placeholder } from 'react-bootstrap';

const DashboardPage = () => {
  const dispatch = useDispatch();
  const { stats, loading, error } = useSelector((state) => state.dashboard);

  useEffect(() => {
    dispatch(fetchDashboardStats());
  }, [dispatch]);

  if (error) {
    return (
      <div className="mt-4">
        <Alert variant="danger">Error al cargar el dashboard: {error}</Alert>
      </div>
    );
  }

  return (
    <div className="container mt-4">
      <h1 className="mb-4">Dashboard General</h1>
      {loading ? (
        // Mostrar placeholders de carga
        <Row>
          {Array.from({ length: 6 }).map((_, index) => (
            <Col key={index} md={4}>
              <Card className="mb-3">
                <Card.Body>
                  <Placeholder as={Card.Title} animation="glow">
                    <Placeholder xs={6} />
                  </Placeholder>
                  <Placeholder as={Card.Text} animation="glow">
                    <Placeholder xs={7} /> <br />
                    <Placeholder xs={4} /> <br />
                    <Placeholder xs={6} /> <br />
                    <Placeholder xs={5} />
                  </Placeholder>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        // Mostrar datos reales cuando no esté cargando
        stats && (
          <Row>
            {/* Usuarios */}
            <Col md={4}>
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>Usuarios</Card.Title>
                  <Card.Text>
                    Total: {stats.usuarios.total}
                    <br />
                    Activos: {stats.usuarios.activos}
                    <br />
                    Administradores: {stats.usuarios.administradores}
                    <br />
                    Socios: {stats.usuarios.socios}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            {/* Encuestas */}
            <Col md={4}>
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>Encuestas</Card.Title>
                  <Card.Text>
                    Total: {stats.encuestas.total}
                    <br />
                    Activas: {stats.encuestas.activas}
                    <br />
                    Respuestas Totales: {stats.encuestas.respuestasTotales}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            {/* Noticias */}
            <Col md={4}>
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>Noticias</Card.Title>
                  <Card.Text>
                    Total: {stats.noticias.total}
                    <br />
                    Última Publicación:{' '}
                    {stats.noticias.ultimaPublicacion || 'No disponible'}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            {/* Beneficios */}
            <Col md={4}>
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>Beneficios</Card.Title>
                  <Card.Text>Activos: {stats.beneficios.activos}</Card.Text>
                </Card.Body>
              </Card>
            </Col>

            {/* Capacitaciones */}
            <Col md={4}>
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>Capacitaciones</Card.Title>
                  <Card.Text>
                    Total: {stats.capacitaciones.total}
                    <br />
                    Completadas: {stats.capacitaciones.completadas}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            {/* Sesiones */}
            <Col md={4}>
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title>Sesiones Activas</Card.Title>
                  <Card.Text>Activas: {stats.sesiones.activas}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )
      )}
    </div>
  );
};

export default DashboardPage;