import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../utils/axiosConfig";

// Enviar correo masivo
export const enviarCorreoMasivo = createAsyncThunk(
  "correo/enviarCorreoMasivo",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await api.post("/api/correo/enviar-masivo", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Importante para enviar archivos
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.error || "Error al enviar el correo masivo"
      );
    }
  }
);

// Obtener historial de correos
export const obtenerHistorialCorreos = createAsyncThunk(
  "correo/obtenerHistorialCorreos",
  async (_, { rejectWithValue }) => {
    try {
      // Haciendo la solicitud a la ruta del backend
      const response = await api.get("/api/correo/historial");

      // Verifica que la respuesta tenga los datos esperados
      if (response.data) {
        return response.data;  // Devuelve los correos desde el backend
      } else {
        return rejectWithValue("No se encontraron correos.");
      }
    } catch (error) {
      // Manejo de errores más detallado
      return rejectWithValue(
        error.response?.data?.error || "Error al obtener el historial de correos"
      );
    }
  }
);

// Eliminar correo
export const eliminarCorreo = createAsyncThunk(
  "correo/eliminarCorreo",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.delete(`/api/correo/${id}`);
      return { id, message: response.data.message || "Correo eliminado con éxito" };
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.error || "Error al eliminar el correo"
      );
    }
  }
);

const correoSlice = createSlice({
  name: "correo",
  initialState: {
    correos: [],
    loading: false,
    error: null,
    successMessage: null,
  },
  reducers: {
    clearSuccessMessage: (state) => {
      state.successMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Enviar correo masivo
      .addCase(enviarCorreoMasivo.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(enviarCorreoMasivo.fulfilled, (state, action) => {
        state.loading = false;
        state.successMessage = action.payload.message || "Correo enviado con éxito";
      })
      .addCase(enviarCorreoMasivo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Obtener historial de correos
      .addCase(obtenerHistorialCorreos.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(obtenerHistorialCorreos.fulfilled, (state, action) => {
        state.loading = false;
        state.correos = action.payload;
      })
      .addCase(obtenerHistorialCorreos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Eliminar correo
      .addCase(eliminarCorreo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(eliminarCorreo.fulfilled, (state, action) => {
        state.loading = false;
        state.successMessage = action.payload.message;
        state.correos = state.correos.filter((correo) => correo.id !== action.payload.id);
      })
      .addCase(eliminarCorreo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearSuccessMessage } = correoSlice.actions;
export default correoSlice.reducer;
