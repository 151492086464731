import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Table, Alert, Placeholder } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Swal from 'sweetalert2';
import {
  fetchNoticias,
  createNoticia,
  updateNoticia,
  deleteNoticia,
} from "../../redux/noticiasSlice";

const NoticiasAdminPage = () => {
  const dispatch = useDispatch();
  const { noticias, loading, error } = useSelector((state) => state.noticias);

  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentNoticia, setCurrentNoticia] = useState({
    titulo: "",
    descripcion: "",
    fecha_publicacion: "",
    imagen: null,
    imagen_url: "", // Para la vista previa de la imagen
  });
  const [validationErrors, setValidationErrors] = useState({
    titulo: "",
    descripcion: "",
    fecha_publicacion: "",
    imagen: "",
  });
  const [isSaving, setIsSaving] = useState(false); // Para el spinner en los botones

  useEffect(() => {
    dispatch(fetchNoticias());
  }, [dispatch]);

  const handleCloseModal = () => {
    setValidationErrors({
      titulo: "",
      descripcion: "",
      fecha_publicacion: "",
      imagen: "",
    });
    setShowModal(false);
    setIsEditMode(false);
    setCurrentNoticia({
      titulo: "",
      descripcion: "",
      fecha_publicacion: "",
      imagen: null,
      imagen_url: "",
    });
  };

  const handleShowModal = (noticia = null) => {
    const today = new Date().toISOString().split('T')[0]; // Obtener la fecha de hoy en formato YYYY-MM-DD

    if (noticia) {
      setCurrentNoticia({
        ...noticia,
        imagen: null,
        imagen_url: noticia.imagen_url,
        fecha_publicacion: noticia.fecha_publicacion || today, // Establecer la fecha de hoy si no está definida
      });
      setIsEditMode(true);
    } else {
      setCurrentNoticia({
        titulo: "",
        descripcion: "",
        fecha_publicacion: today, // Establecer la fecha de hoy por defecto
        imagen: null,
        imagen_url: "",
      });
      setIsEditMode(false);
    }

    setValidationErrors({
      titulo: "",
      descripcion: "",
      fecha_publicacion: "",
      imagen: "",
    });
    setShowModal(true);
  };

  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "titulo":
        if (!value.trim()) {
          error = "El título es obligatorio.";
        } else if (value.length > 255) {
          error = "El título no debe exceder los 255 caracteres.";
        }
        break;
      case "descripcion":
        if (!value.trim()) {
          error = "La descripción es obligatoria.";
        } else if (value.length < 10) {
          error = "La descripción debe tener al menos 10 caracteres.";
        }
        break;
      case "fecha_publicacion":
        if (!value) {
          error = "La fecha de publicación es obligatoria.";
        } else if (new Date(value) > new Date()) {
          error = "La fecha de publicación no puede ser futura.";
        }
        break;
      default:
        break;
    }
    return error;
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "imagen" && files && files[0]) {
      const imagenUrl = URL.createObjectURL(files[0]); // Vista previa de la imagen
      setCurrentNoticia({ ...currentNoticia, imagen: files[0], imagen_url: imagenUrl });
    } else {
      setCurrentNoticia({ ...currentNoticia, [name]: value });
    }

    // Validación en tiempo real
    const error = validateField(name, value);
    setValidationErrors({ ...validationErrors, [name]: error });
  };

  const isFormValid = () => {
    return (
      !validationErrors.titulo &&
      !validationErrors.descripcion &&
      !validationErrors.fecha_publicacion &&
      currentNoticia.titulo.trim() !== "" &&
      currentNoticia.descripcion.trim() !== "" &&
      currentNoticia.fecha_publicacion.trim() !== "" // Asegurar que la fecha no esté vacía
    );
  };

  const handleSaveNoticia = async () => {
    setIsSaving(true);

    try {
      const formData = new FormData();
      formData.append("titulo", currentNoticia.titulo);
      formData.append("descripcion", currentNoticia.descripcion);
      formData.append("fecha_publicacion", currentNoticia.fecha_publicacion);

      // Si estamos editando y no se selecciona una nueva imagen, mantenemos la imagen anterior
      if (isEditMode && !currentNoticia.imagen) {
        formData.append("imagen_url", currentNoticia.imagen_url);
      } else if (currentNoticia.imagen) {
        formData.append("imagen", currentNoticia.imagen);
      }

      if (isEditMode) {
        await dispatch(updateNoticia({ id: currentNoticia.id, noticia: formData })).unwrap();
        Swal.fire({
          icon: 'success',
          title: 'Noticia actualizada',
          text: 'La noticia se ha actualizado correctamente.',
        });
      } else {
        await dispatch(createNoticia(formData)).unwrap();
        Swal.fire({
          icon: 'success',
          title: 'Noticia creada',
          text: 'La noticia se ha creado correctamente.',
        });
      }

      dispatch(fetchNoticias());
      handleCloseModal();
    } catch (error) {
      console.error("Error al guardar la noticia:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un error al guardar la noticia. Por favor, inténtalo nuevamente.',
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeleteNoticia = async (id) => {
    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: "No podrás revertir esta acción.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
    });

    if (result.isConfirmed) {
      try {
        await dispatch(deleteNoticia(id)).unwrap();
        dispatch(fetchNoticias());
        Swal.fire({
          icon: 'success',
          title: 'Noticia eliminada',
          text: 'La noticia se ha eliminado correctamente.',
        });
      } catch (error) {
        console.error("Error al eliminar la noticia:", error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Hubo un error al eliminar la noticia. Por favor, inténtalo nuevamente.',
        });
      }
    }
  };

  return (
    <div>
      <h1>Gestión de Noticias</h1>
      <Button onClick={() => handleShowModal()} className="mb-3">
        Crear Noticia
      </Button>

      {error ? (
        <Alert variant="danger">{error}</Alert>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Título</th>
              <th>Descripción</th>
              <th>Fecha de Publicación</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              // Placeholders de carga
              Array.from({ length: 5 }).map((_, index) => (
                <tr key={index}>
                  <td><Placeholder as="div" animation="glow"><Placeholder xs={6} /></Placeholder></td>
                  <td><Placeholder as="div" animation="glow"><Placeholder xs={8} /></Placeholder></td>
                  <td><Placeholder as="div" animation="glow"><Placeholder xs={12} /></Placeholder></td>
                  <td><Placeholder as="div" animation="glow"><Placeholder xs={6} /></Placeholder></td>
                  <td>
                    <Placeholder as="div" animation="glow">
                      <Button variant="warning" disabled className="me-2">Editar</Button>
                      <Button variant="danger" disabled>Eliminar</Button>
                    </Placeholder>
                  </td>
                </tr>
              ))
            ) : (
              noticias.map((noticia) => (
                <tr key={noticia.id}>
                  <td>{noticia.id}</td>
                  <td>{noticia.titulo}</td>
                  <td>{noticia.descripcion}</td>
                  <td>{noticia.fecha_publicacion}</td>
                  <td>
                    <Button
                      variant="warning"
                      onClick={() => handleShowModal(noticia)}
                      className="me-2"
                    >
                      Editar
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteNoticia(noticia.id)}
                    >
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      )}

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditMode ? "Editar Noticia" : "Crear Noticia"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {validationErrors.general && (
            <Alert variant="danger">{validationErrors.general}</Alert>
          )}

          <Form>
            <Form.Group className="mb-3" controlId="titulo">
              <Form.Label>Título</Form.Label>
              <Form.Control
                type="text"
                name="titulo"
                value={currentNoticia.titulo}
                onChange={handleChange}
                isInvalid={!!validationErrors.titulo}
                placeholder="Ingrese el título de la noticia"
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.titulo}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="descripcion">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="descripcion"
                value={currentNoticia.descripcion}
                onChange={handleChange}
                isInvalid={!!validationErrors.descripcion}
                placeholder="Ingrese la descripción de la noticia"
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.descripcion}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="fecha_publicacion">
              <Form.Label>Fecha de Publicación</Form.Label>
              <Form.Control
                type="date"
                name="fecha_publicacion"
                value={currentNoticia.fecha_publicacion}
                onChange={handleChange}
                isInvalid={!!validationErrors.fecha_publicacion}
                required // Hacer que el campo sea obligatorio
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.fecha_publicacion || "Este campo es obligatorio."}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="imagen">
              <Form.Label>Imagen (Opcional)</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                name="imagen"
                onChange={handleChange}
              />
              {currentNoticia.imagen_url && (
                <div className="mt-2">
                  <img
                    src={currentNoticia.imagen_url}
                    alt="Vista previa"
                    style={{ width: '100%', maxHeight: '200px', objectFit: 'cover' }}
                  />
                  <p className="text-muted mt-2">Imagen actual. Si no selecciona una nueva, se mantendrá esta.</p>
                </div>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={handleSaveNoticia}
            disabled={!isFormValid() || isSaving}
          >
            {isSaving ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {isEditMode ? " Actualizando..." : " Creando..."}
              </>
            ) : isEditMode ? "Actualizar" : "Guardar"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NoticiasAdminPage;