import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { logoutAsync } from '../redux/authSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faPowerOff, faTowerCell } from '@fortawesome/free-solid-svg-icons';
import AdminPanel from './Dashboard/AdminPanel';
import { faUserCircle /* faBell */ } from '@fortawesome/free-regular-svg-icons';
import { fetchNotificaciones, agregarNotificacion } from '../redux/notificacionesSlice';
import NotificacionesMenu from '../components/Notificaciones/NotificacionesMenu';
import socket from '../utils/socket'; // Importar Socket.IO
import '../styles/logo.css';

const Layout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [menuAbierto, setMenuAbierto] = useState(false);
  const notificaciones = useSelector((state) => state.notificaciones.lista);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const cambioClave = useSelector((state) => state.auth.cambioClave);
  const completarEmergencia = useSelector((state) => state.auth.completarEmergencia);
  const rol = useSelector((state) => state.auth.usuario.rol);
  const [fechaHora, setFechaHora] = useState('');

  // Obtener el ID del usuario autenticado
  const usuarioId = useSelector((state) => state.auth.usuario.id);

  useEffect(() => {
    if (isAuthenticated) {
      if (cambioClave || completarEmergencia) {
        navigate('/cambio-clave');
      }
    }
  }, [isAuthenticated, cambioClave, completarEmergencia, rol, navigate]);

  // Conectar Socket.IO cuando el usuario esté autenticado
  useEffect(() => {
    if (!isAuthenticated || !usuarioId) return;

    console.log('Conectando Socket.IO para el usuario:', usuarioId); // Depuración

    // Conectar Socket.IO
    socket.connect();

    // Suscribir al usuario para recibir notificaciones
    socket.emit('suscribirUsuario', usuarioId);
    console.log(`Usuario ${usuarioId} suscrito a notificaciones.`); // Depuración

    // Escuchar nuevas notificaciones
    socket.on('nuevaNotificacion', (notificacion) => {
      console.log('Nueva notificación recibida:', notificacion); // Depuración
      dispatch(agregarNotificacion(notificacion)); // Actualizar el estado de Redux
    });

    // Manejar errores de conexión
    socket.on('connect_error', (error) => {
      console.error('Error de conexión con Socket.IO:', error); // Depuración
    });

    // Desconectar al desmontar el componente
    return () => {
      console.log('Desconectando Socket.IO para el usuario:', usuarioId); // Depuración
      socket.disconnect();
    };
  }, [isAuthenticated, usuarioId, dispatch]);

  // Obtener notificaciones al cargar el componente
  useEffect(() => {
    if (!isAuthenticated) {
      console.log('Usuario no autenticado, no se ejecutará fetchNotificaciones.'); // Depuración
      return;
    }

    console.log('Ejecutando fetchNotificaciones...'); // Depuración
    dispatch(fetchNotificaciones())
      .then((result) => console.log('Resultado de fetchNotificaciones:', result)) // Depuración
      .catch((error) => console.error('Error en fetchNotificaciones:', error)); // Depuración
  }, [dispatch, isAuthenticated]);

  // Actualizar la fecha y hora cada segundo
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      setFechaHora(now.toLocaleDateString('es-ES', options));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  // Manejar la desconexión de Socket.IO al cerrar sesión
  const handleLogout = () => {
    dispatch(logoutAsync())
      .unwrap()
      .then(() => {
        console.log('Usuario cerró sesión, desconectando Socket.IO.'); // Depuración
        socket.disconnect(); // Desconectar Socket.IO
        navigate('/login');
      })
      .catch((error) => {
        console.error('Error al cerrar sesión:', error);
        alert('No se pudo cerrar la sesión. Inténtalo nuevamente.');
      });
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      {/* Navbar principal */}
      <Navbar bg="danger" expand="lg" variant="dark" className="mb-2">
        <Container>
          <Navbar.Brand href="/">
            <FontAwesomeIcon icon={faTowerCell} bounce size="lg" style={{ color: '#bababa' }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/">Inicio</Nav.Link>
              <Nav.Link as={Link} to="/noticias">Noticias</Nav.Link>
              <Nav.Link as={Link} to="/directiva-sindicato">Directiva Sindicato</Nav.Link>
              <Nav.Link as={Link} to="/beneficios">Beneficios</Nav.Link>
              <Nav.Link as={Link} to="/convenios">Convenios</Nav.Link>
              {isAuthenticated && <Nav.Link as={Link} to="/documentos">Documentos</Nav.Link>}
              {!isAuthenticated && <Nav.Link as={Link} to="/login">Mi Sindicato</Nav.Link>}
            </Nav>
            <Nav className="ms-auto d-flex align-items-center">
              {isAuthenticated && (
                <div className="position-relative me-3">

                  {/* Icono de notificaciones - 
                  SECCIÓN DE NOTIFICACIONES - TEMPORALMENTE DESACTIVADA
  Para reactivar:
  1. Descomentar este bloque
  2. Verificar que faBell esté importado de '@fortawesome/free-regular-svg-icons'
  3. Confirmar que los estados menuAbierto y notificaciones estén definidos
  <FontAwesomeIcon
    icon={faBell}
    shake
    size="lg"
    style={{ color: '#FFD43B', cursor: 'pointer' }}
    onClick={() => setMenuAbierto(!menuAbierto)}
    title="Ver notificaciones"
  />
  */}
                  {notificaciones.length > 0 && (
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                      {notificaciones.length}
                    </span>
                  )}
                  <NotificacionesMenu isOpen={menuAbierto} onClose={() => setMenuAbierto(false)} />
                </div>
              )}
              <span className="me-3 text-white">{fechaHora}</span>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Navbar adicional para usuarios autenticados */}
      {isAuthenticated && (
        <Container fluid>
          <Navbar bg="dark" expand="lg" data-bs-theme="dark" className="mb-4">
            <Navbar.Brand href="#" id="home_socio_">
              Home del Socio <FontAwesomeIcon icon={faUserCircle} beat size="sm" style={{ color: '#FFD43B' }} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="auth-navbar-nav" />
            <Navbar.Collapse id="auth-navbar-nav">
              <Nav className="mx-auto">
                <Nav.Link as={Link} to="/profile">Perfil</Nav.Link>
                <Nav.Link as={Link} to="/encuestas">Encuestas</Nav.Link>
                <Nav.Link as={Link} to="/configuracion">Configuración</Nav.Link>
                {isAuthenticated && (
                  <Button variant="outline-light" onClick={handleLogout}>
                    Cerrar Sesión <FontAwesomeIcon icon={faPowerOff} fade size="sm" style={{ color: '#FFD43B' }} />
                  </Button>
                )}
              </Nav>
              {isAdmin && <AdminPanel />}
            </Navbar.Collapse>
          </Navbar>
        </Container>
      )}

      {/* Contenido principal */}
      <Container className="flex-grow-1">
        <Outlet />
      </Container>

      {/* Footer */}
      <footer className="bg-danger text-light py-3 mt-4">
        <Container className="d-flex flex-column flex-md-row justify-content-between align-items-center">
          <p className="mb-0 text-center text-md-start">
            &copy; {new Date().getFullYear()} SINTEREC CLARO. Todos los derechos reservados.
          </p>
          <div className="d-flex flex-column flex-md-row align-items-center">
            <Nav.Link as={Link} to="/" className="text-light">Inicio</Nav.Link>
            <Nav.Link as={Link} to="/noticias" className="text-light ms-md-3">Noticias</Nav.Link>
            <Nav.Link as={Link} to="/directiva-sindicato" className="text-light ms-md-3">Directiva Sindicato</Nav.Link>
            <Nav.Link as={Link} to="/beneficios" className="text-light ms-md-3">Beneficios</Nav.Link>
            <Nav.Link as={Link} to="/convenios" className="text-light ms-md-3">Convenios</Nav.Link>
            <Nav.Link as={Link} to="/privacidad" className="text-light ms-md-3">Política de Privacidad</Nav.Link>
            <a
              href="https://github.com/WilldiazRaM"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light ms-md-3"
              style={{ fontSize: '1.5rem' }}
            >
              <FontAwesomeIcon icon={faGithub} />
            </a>
          </div>
        </Container>
      </footer>
    </div>
  );
};

export default Layout;