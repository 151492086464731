import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTodasRespuestas } from '../../redux/encuestasSlice';
import { Container, Card, Alert, Accordion, Placeholder } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

// Registrar componentes de Chart.js
Chart.register(...registerables);

const ResultadoEncuestaPage = () => {
  const dispatch = useDispatch();
  const { todasRespuestas, loading, error } = useSelector((state) => state.encuestas);

  // Obtener todas las respuestas al cargar el componente
  useEffect(() => {
    dispatch(fetchTodasRespuestas());
  }, [dispatch]);

  // Mostrar un mensaje de error si ocurre algún problema
  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">
          <strong>Error:</strong> {error.message || error.toString()}
        </Alert>
      </Container>
    );
  }

  // Si no hay respuestas, mostrar un mensaje
  if (!todasRespuestas || todasRespuestas.length === 0) {
    return (
      <Container className="mt-5">
        <Alert variant="info">
          No hay respuestas disponibles para las encuestas.
        </Alert>
      </Container>
    );
  }

  return (
    <Container className="mt-5">
      <h1 className="text-center mb-4">Resultados de Todas las Encuestas</h1>
      <p className="text-center mb-4">
        Aquí puedes ver los resultados de las respuestas de los usuarios para todas las encuestas.
      </p>

      {loading ? (
        // Mostrar placeholders de carga
        <Card className="mb-4 shadow-sm">
          <Card.Body>
            <Placeholder as={Card.Title} animation="glow">
              <Placeholder xs={6} />
            </Placeholder>
            <Placeholder as={Card.Text} animation="glow">
              <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
              <Placeholder xs={6} /> <Placeholder xs={8} />
            </Placeholder>
            <Placeholder as={Accordion} animation="glow">
              <Placeholder as={Accordion.Item} eventKey="0">
                <Placeholder as={Accordion.Header} xs={12} />
                <Placeholder as={Accordion.Body} xs={12} />
              </Placeholder>
            </Placeholder>
          </Card.Body>
        </Card>
      ) : (
        // Mostrar datos reales cuando no esté cargando
        todasRespuestas.map((encuesta) => (
          <Card key={encuesta.id} className="mb-4 shadow-sm">
            <Card.Body>
              <Card.Title className="mb-3">{encuesta.titulo}</Card.Title>
              <Card.Text>{encuesta.descripcion}</Card.Text>
              <Card.Text>
                <strong>Fecha de creación:</strong> {encuesta.fecha_creacion}
              </Card.Text>
              <Card.Text>
                <strong>Activo:</strong> {encuesta.activo ? 'Sí' : 'No'}
              </Card.Text>

              {/* Mostrar preguntas y respuestas de la encuesta */}
              {encuesta.preguntas && encuesta.preguntas.length > 0 ? (
                <Accordion>
                  {encuesta.preguntas.map((pregunta) => {
                    const totalRespuestas = pregunta.respuestas.length;
                    const respuestasAgrupadas = pregunta.respuestas.reduce((acc, respuesta) => {
                      acc[respuesta.respuesta] = (acc[respuesta.respuesta] || 0) + 1;
                      return acc;
                    }, {});

                    const labels = Object.keys(respuestasAgrupadas);
                    const data = Object.values(respuestasAgrupadas);

                    return (
                      <Accordion.Item key={pregunta.id} eventKey={pregunta.id}>
                        <Accordion.Header>
                          <strong>{pregunta.pregunta}</strong> ({totalRespuestas} respuestas)
                        </Accordion.Header>
                        <Accordion.Body>
                          {totalRespuestas > 0 ? (
                            <>
                              <ul>
                                {labels.map((label, index) => (
                                  <li key={index}>
                                    <strong>{label}:</strong> {respuestasAgrupadas[label]} respuestas (
                                    {((respuestasAgrupadas[label] / totalRespuestas) * 100).toFixed(2)}%)
                                  </li>
                                ))}
                              </ul>
                              {/* Gráfico de respuestas */}
                              <Bar
                                data={{
                                  labels: labels,
                                  datasets: [
                                    {
                                      label: 'Respuestas',
                                      data: data,
                                      backgroundColor: 'rgba(75, 192, 192, 0.6)',
                                      borderColor: 'rgba(75, 192, 192, 1)',
                                      borderWidth: 1,
                                    },
                                  ],
                                }}
                                options={{
                                  responsive: true,
                                  plugins: {
                                    legend: {
                                      display: false,
                                    },
                                    tooltip: {
                                      enabled: true,
                                    },
                                  },
                                  scales: {
                                    y: {
                                      beginAtZero: true,
                                      title: {
                                        display: true,
                                        text: 'Número de Respuestas',
                                      },
                                    },
                                    x: {
                                      title: {
                                        display: true,
                                        text: 'Respuestas',
                                      },
                                    },
                                  },
                                }}
                              />
                            </>
                          ) : (
                            <Alert variant="warning">No hay respuestas para esta pregunta.</Alert>
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              ) : (
                <Alert variant="warning">No hay preguntas para esta encuesta.</Alert>
              )}
            </Card.Body>
          </Card>
        ))
      )}
    </Container>
  );
};

export default ResultadoEncuestaPage;