import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Table, Alert, Placeholder } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Swal from 'sweetalert2';
import {
  fetchDocumentos,
  uploadDocumento,
  deleteDocumento,
} from "../../redux/documentosSlice";

const DocumentosAdminPage = () => {
  const dispatch = useDispatch();
  const { documentos, loading, error } = useSelector((state) => state.documentos);

  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [documentDetails, setDocumentDetails] = useState({
    nombre: "",
    descripcion: "",
  });
  const [uploadError, setUploadError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    nombre: "",
    descripcion: "",
    archivo: "",
  });

  useEffect(() => {
    dispatch(fetchDocumentos());
  }, [dispatch]);

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedFile(null);
    setDocumentDetails({ nombre: "", descripcion: "" });
    setUploadError(null);
    setValidationErrors({ nombre: "", descripcion: "", archivo: "" });
  };

  const handleShowModal = () => setShowModal(true);

  // Validaciones en tiempo real
  const validateNombre = (nombre) => {
    if (nombre.trim() === "") {
      return "El nombre del documento es obligatorio.";
    }
    if (nombre.length < 3) {
      return "El nombre debe tener al menos 3 caracteres.";
    }
    if (nombre.length > 50) {
      return "El nombre no puede tener más de 50 caracteres.";
    }
    return "";
  };

  const validateDescripcion = (descripcion) => {
    if (descripcion.length > 200) {
      return "La descripción no puede tener más de 200 caracteres.";
    }
    return "";
  };

  const validateArchivo = (file) => {
    if (!file) {
      return "El archivo es obligatorio.";
    }
    const allowedExtensions = /(\.pdf|\.docx|\.txt)$/i;
    if (!allowedExtensions.exec(file.name)) {
      return "El archivo debe ser un PDF, DOCX o TXT.";
    }
    return "";
  };

  const handleNombreChange = (e) => {
    const nombre = e.target.value;
    setDocumentDetails({ ...documentDetails, nombre });
    setValidationErrors({
      ...validationErrors,
      nombre: validateNombre(nombre),
    });
  };

  const handleDescripcionChange = (e) => {
    const descripcion = e.target.value;
    setDocumentDetails({ ...documentDetails, descripcion });
    setValidationErrors({
      ...validationErrors,
      descripcion: validateDescripcion(descripcion),
    });
  };

  const handleArchivoChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setValidationErrors({
      ...validationErrors,
      archivo: validateArchivo(file),
    });
  };

  const isFormValid = () => {
    return (
      validationErrors.nombre === "" &&
      validationErrors.descripcion === "" &&
      validationErrors.archivo === "" &&
      documentDetails.nombre.trim() !== "" &&
      selectedFile !== null
    );
  };

  const handleUploadDocument = async () => {
    setIsUploading(true);
    setUploadError(null);

    const formData = new FormData();
    formData.append("archivo", selectedFile);
    formData.append("nombre", documentDetails.nombre);
    formData.append("descripcion", documentDetails.descripcion);

    try {
      await dispatch(uploadDocumento(formData)).unwrap();
      dispatch(fetchDocumentos()); // Actualizar la lista de documentos
      handleCloseModal(); // Cerrar el modal
      Swal.fire({
        icon: 'success',
        title: 'Documento subido',
        text: 'El documento se ha subido correctamente.',
      });
    } catch (error) {
      console.error("Error al subir el documento:", error);
      setUploadError("Error al subir el documento. Por favor, inténtalo nuevamente.");
    } finally {
      setIsUploading(false);
    }
  };

  const handleDeleteDocument = async (id) => {
    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: "No podrás revertir esta acción.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
    });

    if (result.isConfirmed) {
      try {
        await dispatch(deleteDocumento(id)).unwrap();
        dispatch(fetchDocumentos()); // Actualizar la lista de documentos
        Swal.fire({
          icon: 'success',
          title: 'Documento eliminado',
          text: 'El documento se ha eliminado correctamente.',
        });
      } catch (error) {
        console.error("Error al eliminar el documento:", error);
      }
    }
  };

  return (
    <div>
      <h1>Gestión de Documentos</h1>
      <Button onClick={handleShowModal} className="mb-3">
        Subir Documento
      </Button>

      {error ? (
        <Alert variant="danger">{error}</Alert>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Título</th>
              <th>Descripción</th>
              <th>Fecha de Subida</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              // Placeholders de carga
              Array.from({ length: 5 }).map((_, index) => (
                <tr key={index}>
                  <td><Placeholder as="div" animation="glow"><Placeholder xs={6} /></Placeholder></td>
                  <td><Placeholder as="div" animation="glow"><Placeholder xs={8} /></Placeholder></td>
                  <td><Placeholder as="div" animation="glow"><Placeholder xs={12} /></Placeholder></td>
                  <td><Placeholder as="div" animation="glow"><Placeholder xs={6} /></Placeholder></td>
                  <td>
                    <Placeholder as="div" animation="glow">
                      <Button variant="primary" disabled className="me-2">Ver</Button>
                      <Button variant="danger" disabled>Eliminar</Button>
                    </Placeholder>
                  </td>
                </tr>
              ))
            ) : (
              documentos.map((doc) => (
                <tr key={doc.id}>
                  <td>{doc.id}</td>
                  <td>{doc.titulo}</td>
                  <td>{doc.descripcion || "Sin descripción"}</td>
                  <td>{new Date(doc.fecha_subida).toLocaleDateString("es-CL")}</td>
                  <td>
                    <Button
                      variant="primary"
                      href={doc.url}
                      target="_blank"
                      className="me-2"
                    >
                      Ver
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteDocument(doc.id)}
                    >
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      )}

      {/* Modal para subir documentos */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Subir Documento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {uploadError && <Alert variant="danger">{uploadError}</Alert>}
          <Form>
            <Form.Group className="mb-3" controlId="nombre">
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                value={documentDetails.nombre}
                onChange={handleNombreChange}
                isInvalid={!!validationErrors.nombre}
                placeholder="Ingrese el nombre del documento"
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.nombre}
              </Form.Control.Feedback>
              <Form.Text className="text-muted">
                El nombre debe tener entre 3 y 50 caracteres.
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="descripcion">
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={documentDetails.descripcion}
                onChange={handleDescripcionChange}
                isInvalid={!!validationErrors.descripcion}
                placeholder="Ingrese una descripción (opcional)"
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.descripcion}
              </Form.Control.Feedback>
              <Form.Text className="text-muted">
                La descripción no puede tener más de 200 caracteres.
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="archivo">
              <Form.Label>Archivo</Form.Label>
              <Form.Control
                type="file"
                accept=".pdf,.docx,.txt"
                onChange={handleArchivoChange}
                isInvalid={!!validationErrors.archivo}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.archivo}
              </Form.Control.Feedback>
              <Form.Text className="text-muted">
                Solo se permiten archivos PDF, DOCX o TXT.
              </Form.Text>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={handleUploadDocument}
            disabled={!isFormValid() || isUploading}
          >
            {isUploading ? "Subiendo..." : "Subir"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DocumentosAdminPage;