import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../redux/authSlice';
import loadingReducer from '../redux/loadingSlice'
import noticiasReducer from '../redux/noticiasSlice';
import documentosReducer from '../redux/documentosSlice';
import beneficiosReducer from '../redux/beneficiosSlice';
import profileReducer from '../redux/profileSlice';
import errorReducer from '../redux/errorSlice';
import errorMiddleware from './middlewares/errorMiddleware';
import themeReducer from '../redux/themeSlice';
import correoReducer from '../redux/correosSlice';
import encuestasReducer from '../redux/encuestasSlice';
import dashboardReducer from '../redux/dashboardSlice';
import regionComunaReducer from '../redux/regionComunaSlice';
import conveniosReducer from '../redux/conveniosSlice';
import notificacionesReducer from '../redux/notificacionesSlice';
import exportReducer from "../redux/exportSlice";
import cargaMasivaReducer from "../redux/cargaMasivaSlice";
import directivaReducer from '../redux/directivaSlice';


const store = configureStore({
  reducer: {
    auth: authReducer,
    directiva: directivaReducer,
    cargaMasiva: cargaMasivaReducer,
    export: exportReducer,
    notificaciones: notificacionesReducer,
    regionComuna: regionComunaReducer,
    correo: correoReducer,
    error: errorReducer,
    profile: profileReducer, 
    loading: loadingReducer,
    noticias: noticiasReducer,
    documentos: documentosReducer,
    beneficios: beneficiosReducer,
    theme: themeReducer,
    encuestas: encuestasReducer,
    convenios: conveniosReducer,
    dashboard: dashboardReducer,

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(errorMiddleware),
});

export default store;
