import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Card, Alert, Placeholder } from 'react-bootstrap';
import { fetchDirectiva } from '../redux/directivaSlice';

const DirectivaSindicatoPage = () => {
  const dispatch = useDispatch();
  const { dirigentes, loading, error } = useSelector((state) => state.directiva);

  useEffect(() => {
    dispatch(fetchDirectiva());
  }, [dispatch]);

  if (error) {
    return (
      <Alert variant="danger" className="mt-4">
        <Alert.Heading>Error</Alert.Heading>
        <p>{error}</p>
      </Alert>
    );
  }

  return (
    <Container className="mt-4">
      <h1 className="text-center mb-4">Directiva del Sindicato</h1>
      <Row className="gy-4">
        {loading ? (
          // Mostrar placeholders de carga
          Array.from({ length: 6 }).map((_, index) => (
            <Col key={index} md={6} lg={4}>
              <Card className="shadow-sm h-100">
                {/* Contenedor para la imagen con placeholder */}
                <div style={{ height: '250px', backgroundColor: '#f0f0f0' }}>
                  <Placeholder as={Card.Img} animation="glow" style={{ height: '100%', width: '100%' }} />
                </div>
                <Card.Body className="d-flex flex-column">
                  <Placeholder as={Card.Title} animation="glow">
                    <Placeholder xs={8} />
                  </Placeholder>
                  <Placeholder as={Card.Subtitle} animation="glow">
                    <Placeholder xs={6} />
                  </Placeholder>
                  <Placeholder as={Card.Text} animation="glow">
                    <Placeholder xs={10} />
                  </Placeholder>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          // Mostrar datos reales cuando no esté cargando
          dirigentes.map((dirigente) => (
            <Col key={dirigente.id} md={6} lg={4}>
              <Card className="shadow-sm h-100 hover-effect">
                <Card.Img
                  variant="top"
                  src={dirigente.imagen_url || 'https://via.placeholder.com/150'} // Imagen predeterminada si no hay URL
                  alt={`Foto de ${dirigente.nombre}`}
                  style={{ height: '250px', objectFit: 'cover' }}
                />
                <Card.Body className="d-flex flex-column">
                  <Card.Title className="fw-bold text-primary">{dirigente.nombre}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted fw-bold">{dirigente.cargo}</Card.Subtitle>
                  <Card.Text className="mt-auto">
                    <strong className="text-success">Email:</strong>{' '}
                    <a href={`mailto:${dirigente.email}`} className="text-decoration-none">
                      {dirigente.email}
                    </a>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))
        )}
      </Row>
    </Container>
  );
};

export default DirectivaSindicatoPage;