import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/axiosConfig';

// Thunks
// Obtener todos los documentos
export const fetchDocumentos = createAsyncThunk(
  'documentos/fetchDocumentos',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/api/documentos/listar');
      return response.data;
    } catch (error) {
      // Devuelve un error estructurado para el middleware
      return rejectWithValue({
        message: error.response?.data?.message || 'Error al obtener documentos',
        status: error.response?.status || 500,
      });
    }
  }
);

// Subir un documento
export const uploadDocumento = createAsyncThunk(
  'documentos/uploadDocumento',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await api.post('/api/documentos/subir', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error al subir documento');
    }
  }
);

// Descargar un documento
export const downloadDocumento = createAsyncThunk(
  'documentos/downloadDocumento',
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.get(`/api/documentos/descargar/${id}`, {
        responseType: 'blob',
      });

      // Crear URL del blob
      const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
      
      // Crear enlace y simular click
      const link = document.createElement('a');
      link.href = blobUrl;
      
      // Obtener el nombre del archivo del header Content-Disposition
      const contentDisposition = response.headers['content-disposition'];
      let fileName = `documento_${id}`;
      
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch && fileNameMatch[1]) {
          fileName = fileNameMatch[1];
        }
      }
      
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      // Liberar memoria
      window.URL.revokeObjectURL(blobUrl);
      
      return { id };
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error al descargar documento');
    }
  }
);


// thunk para visualización
export const viewDocumento = createAsyncThunk(
  'documentos/viewDocumento',
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.get(`/api/documentos/ver/${id}`, {
        responseType: 'blob',
      });
      
      return {
        id,
        blobUrl: window.URL.createObjectURL(new Blob([response.data])),
        fileName: response.headers['content-disposition']?.match(/filename="(.+)"/)?.[1] || `documento_${id}`
      };
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error al visualizar documento');
    }
  }
);


// Eliminar un documento
export const deleteDocumento = createAsyncThunk(
  'documentos/deleteDocumento',
  async (nombre, { rejectWithValue }) => {
    try {
      await api.delete(`/api/documentos/eliminar/${nombre}`);
      return nombre;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error al eliminar documento');
    }
  }
);

const documentosSlice = createSlice({
  name: 'documentos',
  initialState: {
    documentos: [],
    documentoSeleccionado: null,
    loading: false,
    error: null,
  },
  reducers: {
    clearDocumentoSeleccionado: (state) => {
      state.documentoSeleccionado = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Obtener documentos
      .addCase(fetchDocumentos.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDocumentos.fulfilled, (state, action) => {
        state.loading = false;
        state.documentos = Array.isArray(action.payload) ? action.payload : [];
      })      
      .addCase(fetchDocumentos.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Subir documento
      .addCase(uploadDocumento.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadDocumento.fulfilled, (state, action) => {
        state.loading = false;
        state.documentos.push(action.payload);
      })
      .addCase(uploadDocumento.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Descargar documento
      .addCase(downloadDocumento.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(downloadDocumento.fulfilled, (state, action) => {
        state.loading = false;
        state.documentoSeleccionado = action.payload.id;
      })
      .addCase(downloadDocumento.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Eliminar documento
      .addCase(deleteDocumento.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDocumento.fulfilled, (state, action) => {
        state.loading = false;
        state.documentos = state.documentos.filter((doc) => doc.nombre !== action.payload);
      })
      .addCase(deleteDocumento.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearDocumentoSeleccionado } = documentosSlice.actions;
export default documentosSlice.reducer;
