import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { agregarNotificacion } from '../../redux/notificacionesSlice'; // Importa la acción para agregar notificaciones
import socket from '../../utils/socket'; // Importa el socket

const NotificacionesMenu = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();

  // Obtener el estado de las notificaciones desde Redux
  const { lista: notificaciones, status, error } = useSelector((state) => state.notificaciones);

  // Escuchar eventos de WebSocket para nuevas notificaciones
  useEffect(() => {
    // Escuchar el evento 'nuevaNotificacion'
    socket.on('nuevaNotificacion', (notificacion) => {
      console.log('Nueva notificación recibida:', notificacion);
      dispatch(agregarNotificacion(notificacion)); // Despachar la acción para agregar la notificación al estado
    });

    // Limpiar el listener al desmontar el componente
    return () => {
      socket.off('nuevaNotificacion');
    };
  }, [dispatch]);

  // Si el menú no está abierto, no renderizar nada
  if (!isOpen) return null;

  return (
    <div className="notificaciones-menu">
      <h3>Notificaciones</h3>

      {/* Mostrar mensaje de carga */}
      {status === 'loading' && <p>Cargando notificaciones...</p>}

      {/* Mostrar mensaje de error */}
      {status === 'failed' && <p>Error: {error}</p>}

      {/* Mostrar notificaciones o mensaje si no hay notificaciones */}
      {status === 'succeeded' && (
        <ul>
          {notificaciones.length === 0 ? (
            <p>No hay notificaciones disponibles.</p>
          ) : (
            notificaciones.map((notificacion) => (
              <li key={notificacion.id}>{notificacion.mensaje}</li>
            ))
          )}
        </ul>
      )}

      {/* Botón para cerrar el menú */}
      <button onClick={onClose}>Cerrar</button>
    </div>
  );
};

export default NotificacionesMenu;