// src/redux/directivaSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../utils/axiosConfig';

// Async Thunks para las operaciones CRUD
export const fetchDirectiva = createAsyncThunk(
  'directiva/fetchDirectiva',
  async () => {
    const response = await api.get('/api/directiva');
    return response.data;
  }
);

export const createDirigente = createAsyncThunk(
  'directiva/createDirigente',
  async (dirigenteData) => {
    const response = await api.post('/api/directiva', dirigenteData);
    return response.data;
  }
);

export const updateDirigente = createAsyncThunk(
  'directiva/updateDirigente',
  async ({ id, dirigenteData }) => {
    const response = await api.put(`/api/directiva/${id}`, dirigenteData);
    return response.data;
  }
);

export const deleteDirigente = createAsyncThunk(
  'directiva/deleteDirigente',
  async (id) => {
    await api.delete(`/api/directiva/${id}`);
    return id;
  }
);

// Slice de Redux
const directivaSlice = createSlice({
  name: 'directiva',
  initialState: {
    dirigentes: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch Directiva
      .addCase(fetchDirectiva.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDirectiva.fulfilled, (state, action) => {
        state.loading = false;
        state.dirigentes = action.payload;
      })
      .addCase(fetchDirectiva.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Create Dirigente
      .addCase(createDirigente.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createDirigente.fulfilled, (state, action) => {
        state.loading = false;
        state.dirigentes.push(action.payload);
      })
      .addCase(createDirigente.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Update Dirigente
      .addCase(updateDirigente.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateDirigente.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.dirigentes.findIndex((d) => d.id === action.payload.id);
        if (index !== -1) {
          state.dirigentes[index] = action.payload;
        }
      })
      .addCase(updateDirigente.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Delete Dirigente
      .addCase(deleteDirigente.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteDirigente.fulfilled, (state, action) => {
        state.loading = false;
        state.dirigentes = state.dirigentes.filter((d) => d.id !== action.payload);
      })
      .addCase(deleteDirigente.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default directivaSlice.reducer;