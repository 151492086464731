import React, { useState } from "react";
import Swal from 'sweetalert2';

const FileUpload = ({ onFileSelect }) => {
  const [fileName, setFileName] = useState('');

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const validExtensions = ['xlsx', 'xls'];
      const fileExtension = file.name.split('.').pop().toLowerCase();
      
      if (!validExtensions.includes(fileExtension)) {
        Swal.fire({
          icon: 'error',
          title: 'Archivo no válido',
          text: 'Por favor, sube solo archivos Excel (.xlsx o .xls)',
        });
        event.target.value = '';
        setFileName('');
        return;
      }
      
      setFileName(file.name);
      onFileSelect(file);
    }
  };

  return (
    <div className="file-upload">
      <input 
        type="file" 
        accept=".xlsx,.xls" 
        onChange={handleFileChange} 
      />
      {fileName && <p className="mt-2">Archivo seleccionado: {fileName}</p>}
    </div>
  );
};

export default FileUpload;